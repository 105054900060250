import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { getVerticalSpacing } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const DividerBlock = ({
  type,
  lineColour,
  verticalSpacing,
  backgroundImage,
  backgroundColour,
}) => {
  return (
    <Wrapper
      backgroundColour={backgroundColour}
      verticalSpacing={getVerticalSpacing(verticalSpacing)}
    >
      {type === "spacer" && <Spacer />}
      {type === "line" && <Line lineColour={lineColour} />}
      {type === "image" && (
        <Img fluid={backgroundImage.imageFile.childImageSharp.fluid} />
      )}
    </Wrapper>
  )
}
export default DividerBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
  padding: ${({ verticalSpacing }) => `${verticalSpacing} 0`};
`
const Spacer = styled.div`
  padding: ${({ verticalSpacing }) => `${verticalSpacing} 0`};
`
const Line = styled.div`
  padding: ${({ verticalSpacing }) => `${verticalSpacing} 0`};
  &:before {
    content: "";
    background: ${({ lineColour }) => lineColour};
    height: 2px;
    border-radius: 4px;
    width: 30%;
    position: absolute;
    left: calc(50% - 15%);
  }
`
