import React from "react"
import styled from "styled-components"
import Inner from "../../dsm/layout/Inner"
import Button from "../../dsm/buttons"
import { getVerticalSpacing, getWrapper } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ButtonRowBlock = ({
  title,
  buttons,
  headerSize,
  textColour,
  description,
  contentLayout,
  verticalSpacing,
  backgroundColour,
  backgroundImage,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()
  let Wrapper = getWrapper({ backgroundImage, backgroundColour })

  const classes = [contentLayout, "button-row-block"]

  const hasHeading = title || description

  return (
    <Wrapper>
      <Section
        lightSkin={lightSkin}
        textColour={textColour}
        className={classes.join(" ")}
        hasHeading={hasHeading.length > 0}
        verticalSpacing={verticalSpacing}
      >
        <Inner>
          {title && (
            <Heading
              as={headerSize}
              textColour={textColour}
              className="heading"
            >
              {title}
            </Heading>
          )}
          {description && (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          )}
          {buttons && buttons.length > 0 && (
            <div className="button-row">
              {buttons.map((button) => (
                <Button
                  key={button.link}
                  to={button.link}
                  label={button.label}
                  isExternal={button.isExternal}
                  type={button.type}
                  icon={button.icon}
                  color={button.buttonColour || textColour}
                  image={button.image}
                />
              ))}
            </div>
          )}
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default ButtonRowBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ textColour }) => textColour};
  background-color: ${({ backgroundColour }) => backgroundColour};
  text-align: center;
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  &.half-left {
    .inner .heading {
      max-width: 50%;
      text-align: left;
      margin-left: 10px;
    }
    .inner .button-row {
      max-width: 50%;
      justify-content: flex-start;
    }
  }
  &.half-right {
    .inner .heading {
      max-width: 50%;
      text-align: right;
      margin: 0 10px 0 auto;
    }
    .inner .button-row {
      max-width: 50%;
      justify-content: flex-end;
      margin: 0 0 0 auto;
    }
  }
  .button-row {
    display: flex;
    justify-content: center;
    a {
      margin: ${({ hasHeading }) =>
        hasHeading ? `25px 10px 0 10px` : `0 10px`};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    &.half-left,
    &.half-right {
      .inner .heading,
      .inner .description {
        max-width: 100%;
      }
      .inner .button-row {
        max-width: 100%;
        justify-content: flex-start;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .button-row {
      display: block;
      a {
        margin: ${({ hasHeading }) => (hasHeading ? `25px 0 0 0` : `0`)};
      }
    }
  }
`
const Heading = styled.h3`
  color: ${({ textColour }) => textColour};
  margin-top: 0;
`
