import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Inner from "../../dsm/layout/Inner"
import { getVerticalSpacing, getInverseColour } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const TextBlock = ({
  text,
  columns,
  columnGap,
  textColour,
  contentLayout,
  verticalSpacing,
  backgroundColour,
  backgroundImage,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()
  const isPreview = backgroundImage?.mediaItemUrl
  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if we have an image, show a background
  if (backgroundImage !== null) {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? {
          background: `background: url(${isPreview});`,
          as: "div",
          backgroundColour: backgroundColour,
        }
      : {
          fluid: backgroundImage.imageFile?.childImageSharp?.fluid,
          backgroundColour: backgroundColour,
        }
  } else {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  }
  const classes = [contentLayout, "text-block"]

  return (
    <Wrapper {...wrapperProps}>
      <Section
        lightSkin={lightSkin}
        textColour={textColour}
        className={classes.join(" ")}
        verticalSpacing={verticalSpacing}
        backgroundColour={backgroundColour}
        columns={columns}
        columnGap={columnGap}
      >
        <Inner>
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="content-container"
          />
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default TextBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: ${({ backgroundColour }) => backgroundColour};
  ${({ background }) => background}
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ textColour }) => textColour};
  }
  p {
    margin-top: 0;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  &.boxed-left,
  &.boxed-right {
    .content-container {
      width: 40%;
      background-color: ${({ textColour, lightSkin, theme }) =>
        textColour
          ? `${getInverseColour(textColour)}40`
          : lightSkin
          ? theme.colors.tusk[100]
          : theme.colors.midnight[100]};

      padding: 10px 25px;
      border-radius: 8px;
    }
  }
  &.boxed-left {
    .content-container {
      margin-left: 0;
    }
  }
  &.boxed-right {
    .content-container {
      margin-right: 0;
      margin-left: auto;
    }
  }
  .content-container {
    column-count: ${({ columns }) => columns};
    column-gap: ${({ columnGap }) => columnGap};
  }
  img {
    width: auto;
  }
  .aligncenter {
    margin: 0 auto;
    display: block;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    &.boxed-left,
    &.boxed-right {
      .content-container {
        width: calc(100% - 50px);
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .content-container {
      column-count: 1;
      column-gap: 0;
    }
  }
`
