import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-apollo"
import gql from "graphql-tag"
import { clean_up_token, eraseToken, getParams } from "./utils/index."
// import { clean_up_token, eraseToken, getParams } from "./utils/index"

const usePagePreview = (query) => {
  const [login, { data: mData }] = useMutation(LOGIN)
  var [queryConfig, setQueryConfig] = useState({ skip: true })

  const { loading, error, data } = useQuery(query, queryConfig)

  useEffect(() => {
    var token = mData?.login?.authToken
    if (token) {
      // console.log("UPDATING: token")
      localStorage.setItem("token", token)
      localStorage.setItem("token-date", new Date())
    }
  }, [mData])

  useEffect(() => {
    var query = getParams()
    var interval_id
    function clean(id) {
      setQueryConfig({ skip: true })
      return clean_up_token(id)
    }

    if (!query) return () => clean(interval_id)

    var revisionID = query[process.env.GATSBY_REVISION_PARAM]
    var latest = query[process.env.GATSBY_LATEST_PARAM]

    if (!(revisionID || latest)) {
      return () => clean(interval_id)
    }

    eraseToken()
    login()
      .then((res) => {
        setQueryConfig({
          skip: false,
          variables: { id: revisionID },
        })
        interval_id = setInterval(() => login(), "900000")
      })
      .catch((er) => console.log({ er }))

    return () => clean(interval_id)
  }, [])

  const types = ["page", "post", "item"]

  var revision = types.reduce(
    (a, c) => data?.[c]?.revisions?.nodes[0] || a,
    null
  )

  return { loading, error, data, revision }
}

const LOGIN = gql`
  mutation LoginUser {
    login(
      input: {
        clientMutationId: "mutate"
        username: "russel"
        password: "J6PF9EVLWwb4eXSy"
      }
    ) {
      authToken
      user {
        id
        name
      }
    }
  }
`
export default usePagePreview
