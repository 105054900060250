import removeGatsbyImages from "../utils/removeGatsbyImages"

var config = {
  transformer: removeGatsbyImages,
  things: [
    {
      condition: ({ params }) => params?.[process.env.GATSBY_REVISION_PARAM],
      name: process.env.GATSBY_REVISION_PARAM,
      gqlString: ({ pageContext }) => `
        query($id: Int!){
          page(id: "${pageContext?.page?.id}") {
          revisions(where: {id: $id}) {
            nodes {
                id
                title
                pageBuilder {
                  layouts {
                    ${pageContext?.layoutsData?.replace(/WPGraphQL_/g, "")}
                  }
                }
          }
        }
      }
    }
      `,
    },
    {
      condition: ({ params }) => !!params?.[process.env.GATSBY_LATEST_PARAM],
      name: process.env.GATSBY_LATEST_PARAM,
      gqlString: ({ pageContext }) => `
      query {
        page(id: "${pageContext?.page?.id}") {
          revisions(first: 1) {
            nodes {
              id
              title
              pageBuilder {
                layouts {
                  ${pageContext?.layoutsData?.replace(/WPGraphQL_/g, "")}
                }
              }
            }
          }
        }
      }
      `,
    },
  ],
}

export default config
