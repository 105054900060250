import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Inner from "../../dsm/layout/Inner"
import { getVerticalSpacing } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const LogoWallBlock = ({
  title,
  logos,
  headerSize,
  textColour,
  description,
  contentLayout,
  verticalSpacing,
  backgroundColour,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()

  const classes = [contentLayout, "logo-wall-block"]

  return (
    <Section
      lightSkin={lightSkin}
      textColour={textColour}
      className={classes.join(" ")}
      verticalSpacing={verticalSpacing}
      backgroundColour={backgroundColour}
    >
      <Inner>
        {title && (
          <Heading as={headerSize} textColour={textColour} className="heading">
            {title}
          </Heading>
        )}
        {description && (
          <p
            dangerouslySetInnerHTML={{ __html: description }}
            className="description"
          />
        )}
        <LogosWrapper cols={getColumns(logos.length)}>
          {logos.map((logo, i) => (
            <div className="logo" key={i}>
              {logo?.mediaItemUrl ? (
                <img src={logo?.mediaItemUrl} alt={logo.altText} />
              ) : (
                <Img
                  fluid={logo.imageFile.childImageSharp.fluid}
                  alt={logo.altText}
                />
              )}
            </div>
          ))}
        </LogosWrapper>
      </Inner>
    </Section>
  )
}
export default LogoWallBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Section = styled.section`
  text-align: center;
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ textColour }) => textColour};
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour : "rgba(0, 0, 0, 0.08)"};
  h2 {
    color: ${({ textColour }) => textColour};
    margin: 0;
  }
  .description {
    margin-bottom: 45px;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
`

const LogosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ cols }) => cols}, 1fr);
  grid-gap: 80px;
  margin-top: 30px;
  .logo {
    display: grid;
    align-items: center;
  }
  img {
    mix-blend-mode: multiply;
    object-fit: scale-down !important;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Heading = styled.h3`
  color: ${({ textColour }) => textColour};
  margin-top: 0;
`

///////////////////////////////////////////////////////////////////////////////
// 👌 Helper Functions
///////////////////////////////////////////////////////////////////////////////
const getColumns = (length) => {
  let cols

  if (length < 4) {
    cols = length
  } else {
    cols = 4
  }

  return cols
}
