import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Countdown from "react-countdown"

import { getVerticalSpacing } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////

const CountdownTimerBlock = ({
  date,
  color,
  message,
  included,
  fontStyles,
  orientation,
  numberStyles,
  messageStyles,
  backgroundImage,
  verticalSpacing,
  backgroundColour,
  completionMessage,
}) => {
  const isPreview = backgroundImage?.mediaItemUrl
  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (!backgroundImage) {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  } else {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? { background: `background: url(${isPreview});`, as: "div" }
      : {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
        }
  }

  // Random component
  const Completionist = () => (
    <div className="completion-message message">{completionMessage}</div>
  )

  const pluralize = (num, word) => (num === 1 ? word : `${word}s`).concat(" ")

  // Renderer callback with condition

  const renderer = (props) => {
    const { completed } = props
    if (completed) {
      // Render a completed state
      return <Completionist />
    } else {
      // Render a countdown
      return (
        <div id="98asd">
          <h2 className="message">{message}</h2>
          <div className="time">
            {[...included].reverse().map((key) => {
              var value = props.formatted[key]
              return (
                <span className={`text ${key}`} key={key}>
                  <span className="number">{value}</span>
                  {pluralize(value, ` ${key.slice(0, key.length - 1)}`)}
                </span>
              )
            })}
          </div>
        </div>
      )
    }
  }

  return (
    <Wrapper
      {...wrapperProps}
      {...{
        color,
        fontStyles,
        orientation,
        numberStyles,
        messageStyles,
        verticalSpacing,
        backgroundColour,
      }}
    >
      <Countdown
        date={new Date(date)}
        renderer={renderer}
        zeroPadTime={2}
        zeroPadDays={2}
      />
    </Wrapper>
  )
}
export default CountdownTimerBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const getSize = (config) => ({ small: 10, medium: 25, large: 40 }[config.size])
const getWeight = (config) =>
  ({ light: 100, regular: 300, bold: 900 }[config.weight])

const getStyles = (config) =>
  `color: ${config.color};font-size: ${getSize(
    config
  )}px;font-weight: ${getWeight(config)};`

// ///////////////
const shared = ({
  color,
  theme,
  orientation,
  numberStyles,
  fontStyles,
  messageStyles,
}) => `
  align-items: center;
  justify-content: center;
  color: ${color};
  div.time,div.message,div.completion-message {
    text-align:center;
  }
  h2 {
    text-align:center;
    margin-bottom:30px;
  }
  .time{
    display:grid;
    grid-gap:15px;
    max-width:600px;
    margin: 20px auto;
    ${
      orientation === "vertical"
        ? "grid-template-columns:1fr;"
        : "grid-template-columns:1fr 1fr 1fr 1fr;"
    }
  }
  .number{
    ${getStyles(numberStyles)}
    border-radius:4px;
    background: rgba(0,0,0,0.2);
  }
  .text{
    ${getStyles(fontStyles)}
    display:grid;
  }
  .message{${getStyles(messageStyles)}}

@media (max-width: 480px) {
  .time .text {
    font-size:16px;
  }
  .time .text .number {
    font-size:32px;
  }
}
`

const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  ${({ background }) => background};
  padding: ${({ verticalSpacing }) => getVerticalSpacing(verticalSpacing)} 20px;
  ${shared}
`
const WrapperColor = styled.div`
  padding: ${({ verticalSpacing }) => getVerticalSpacing(verticalSpacing)} 20px;
  background-color: ${({ backgroundColour }) => backgroundColour};
  ${shared}
`
