import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import InnerHTML from "dangerously-set-html-content"

import Icon from "../../../dsm/icons/icon"
import Inner from "../../../dsm/layout/Inner"
import colors from "../../../dsm/colors"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import getFlexiOptionsContent from "../../../options/getFlexiOptionsContent"
import {
  formatPhoneToLink,
  formatEmailToLink,
  formatPhoneToWhatsappLink,
} from "../../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ContactInfoLayoutA = ({
  title,
  vSpacing,
  headerSize,
  textColour,
  description,
  backgroundImage,
  backgroundColour,
  colorHasPriority,
  isPreview,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()
  const { contactDetails } = getFlexiOptionsContent()

  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (colorHasPriority) {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  } else {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? { background: `background: url(${isPreview});`, as: "div" }
      : {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
          backgroundColour: backgroundColour,
        }
  }
  const classes = ["contact-info-block"]

  // determine columns based on available data
  let columns = 0
  if (contactDetails.address.length > 0 && contactDetails.address !== null) {
    columns += 1
  }
  if (
    contactDetails.telephoneNumbers !== "" &&
    contactDetails.telephoneNumbers !== null
  ) {
    columns += 1
  }
  if (contactDetails.emails.length > 0) {
    columns += 1
  }

  // Get Text Colour
  const finalText = textColour
    ? textColour
    : lightSkin
    ? colors.midnight[400]
    : colors.tusk[100]

  return (
    <Wrapper {...wrapperProps}>
      <Section
        columns={columns}
        vSpacing={vSpacing}
        lightSkin={lightSkin}
        finalText={finalText}
        className={classes.join(" ")}
        headerSpacing={
          contactDetails.telephoneNumbers === null ? "none" : "40px"
        }
      >
        <Inner>
          <div className="heading-row">
            {title && (
              <Heading
                as={headerSize}
                finalText={finalText}
                className="heading"
              >
                {title}
              </Heading>
            )}
            {description && (
              <p
                dangerouslySetInnerHTML={{ __html: description }}
                className="description"
              />
            )}
          </div>
          <div className="tel-row">
            {contactDetails.telephoneNumbers &&
              contactDetails.telephoneNumbers.length > 0 && (
                <div>
                  <h4>
                    <Icon name="mobile" color={`${finalText}80`} />
                    Contact Number
                    {contactDetails.telephoneNumbers.length > 1 && "s"}
                  </h4>
                  {contactDetails.telephoneNumbers.map(
                    ({ telephoneNumber, type }, i) => (
                      <p
                        key={i}
                        dangerouslySetInnerHTML={{
                          __html: {
                            call: formatPhoneToLink,
                            whatsapp: formatPhoneToWhatsappLink,
                          }[type](telephoneNumber),
                        }}
                      />
                    )
                  )}
                </div>
              )}
            {contactDetails.emails && contactDetails.emails.length > 0 && (
              <div>
                <h4 className="email">
                  <Icon name="paper-plane" color={`${finalText}80`} />
                  Email{contactDetails.emails.length > 1 && "s"}
                </h4>
                {contactDetails.emails.map((entry) => (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: formatEmailToLink(entry.email),
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          {contactDetails.address && (
            <div className="address-row">
              <h4>
                <Icon name="map-marked" color={`${finalText}80`} />
                Address
              </h4>
              <InnerHTML html={contactDetails.address} />
            </div>
          )}
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default ContactInfoLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: ${({ backgroundColour }) => backgroundColour};

  ${({ background }) => background}
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  color: ${({ finalText }) => finalText};
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: ${({ finalText }) => finalText};
  }
  a {
    color: ${({ finalText }) => finalText};
    border-color: ${({ finalText }) => finalText};
    &:hover {
      border-color: ${({ finalText }) => finalText};
    }
  }
  .inner {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    h4 {
      margin: 0;
      &.email {
        margin-top: ${({ headerSpacing }) => headerSpacing};
      }
      .icon {
        margin-right: 12px;
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakSmall}) and (max-width: ${({
      theme,
    }) => theme.breakLarge}) {
    .inner {
      grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
      grid-gap: ${({ columns }) => (columns > 1 ? "40px" : "0")};
      justify-items: center;
    }
    .heading-row {
      grid-column: 1 / span 3;
      grid-row: 1;
      text-align: center;
    }
    .tel-row {
      grid-column: 1 / span 2;
      grid-row: 2;
      text-align: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      h4.email {
        margin-top: 0;
      }
    }
    .address-row {
      text-align: center;
      grid-column: 3;
      grid-row: 2;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .inner {
      grid-template-columns: 1fr;
      grid-gap: 40px;
      text-align: center;
    }
  }
`
const Heading = styled.h3`
  color: ${({ finalText }) => finalText};
  margin-top: 0;
`
