import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Inner from "../../dsm/layout/Inner"
import Button from "../../dsm/buttons"
import { getVerticalSpacing, getWrapper } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import Card from "../../templates/post/card/LayoutA"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const BlogListBlock = ({
  title,
  headerSize,
  textColour,
  description,
  buttonLabel,
  itemsPerRow,
  contentLayout,
  numberOfPosts,
  showMoreButton,
  verticalSpacing,
  backgroundImage,
  backgroundColour,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()
  let Wrapper = getWrapper({ backgroundImage, backgroundColour })
  const classes = [contentLayout, "blog-list-block"]

  // Top 5 latest posts
  const {
    wpgraphql: {
      posts: { edges },
    },
  } = useStaticQuery(LATEST_POSTS)

  // reduce to user chosen number of posts
  const posts = edges.slice(0, numberOfPosts)

  return (
    <Wrapper>
      <Section
        lightSkin={lightSkin}
        textColour={textColour}
        className={classes.join(" ")}
        itemsPerRow={itemsPerRow}
        verticalSpacing={verticalSpacing}
      >
        <Inner>
          {title && (
            <Heading
              as={headerSize}
              textColour={textColour}
              className="heading"
            >
              {title}
            </Heading>
          )}
          {description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          <div className="post-list">
            {posts &&
              posts.map((post) => <Card key={post.postId} post={post.node} />)}
          </div>
          {showMoreButton && (
            <div className="button-row">
              <Button
                to={`/${process.env.GATSBY_BLOG_SLUG}/`}
                label={buttonLabel}
                icon="arrow-right"
              />
            </div>
          )}
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default BlogListBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Section = styled.section`
  text-align: center;
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ textColour }) => textColour};
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour : "rgba(0, 0, 0, 0.08)"};
  h2 {
    color: ${({ textColour }) => textColour};
    margin: 0;
  }
  .description {
    margin-bottom: 45px;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .button-row {
    margin-top: 60px;
    display: grid;
    justify-items: center;
  }
  .post-list {
    display: grid;
    grid-template-columns: repeat(${({ itemsPerRow }) => itemsPerRow}, 1fr);
    grid-gap: 30px;
    a {
      margin: 0;
    }
  }
  .post-list a .footer {
    grid-template-columns: ${({ itemsPerRow }) =>
      itemsPerRow > 2 ? "1fr" : "auto auto"};
    grid-gap: 10px;
    .categories {
      justify-content: ${({ itemsPerRow }) =>
        itemsPerRow > 2 ? "flex-start" : "flex-end"};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .post-list {
      grid-template-columns: ${({ itemsPerRow }) =>
        itemsPerRow > 1 ? "1fr 1fr" : "1fr"};
      a .footer {
        grid-template-columns: ${({ itemsPerRow }) =>
          itemsPerRow > 1 ? "1fr" : "auto auto"};
        grid-gap: 10px;
        .categories {
          justify-content: ${({ itemsPerRow }) =>
            itemsPerRow > 1 ? "flex-start" : "flex-end"};
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .post-list {
      grid-template-columns: 1fr;
      a .footer {
        grid-template-columns: 1fr;
        grid-gap: 10px;
        .categories {
          justify-content: flex-start;
        }
      }
    }
  }
`

const Heading = styled.h3`
  color: ${({ textColour }) => textColour};
  margin-top: 0;
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const LATEST_POSTS = graphql`
  {
    wpgraphql {
      posts(first: 5) {
        edges {
          node {
            date
            link
            title
            postId
            excerpt
            featuredImage {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(quality: 90, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            author {
              uri
              name
              slug
              avatar {
                url
              }
            }
            categories {
              nodes {
                id
                link
                name
              }
            }
          }
        }
      }
    }
  }
`
