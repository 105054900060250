import React, { useState, useEffect } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import posed, { PoseGroup } from "react-pose"
import Inner from "../../../dsm/layout/Inner"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import Icon from "../../../dsm/icons/icon"
import { getInverseColour, getAssetColours } from "../../../utils"
import colors from "../../../dsm/colors"
import FluidImage from "../../../components/Images/FluidImage"

///////////////////////////////////////////////////////////////////////////////
// 🛠 Component
///////////////////////////////////////////////////////////////////////////////
const TestimonialsLayoutA = ({
  title,
  duration,
  autoPlay,
  vSpacing,
  textColour,
  isPreview,
  description,
  assetColours,
  testimonials,
  contentLayout,
  backgroundImage,
  backgroundColour,
  colorHasPriority,
  boxBackgroundColour,
}) => {
  const { lightSkin, shape } = getFlexiOptionsBranding()

  // Slider Specific Functions
  const [active, setActive] = useState(0)
  const [inMotion, setInMotion] = useState(true)

  const handlePrev = () => {
    setActive((p) => (p === 0 ? testimonials.length - 1 : p - 1))
  }
  const handleNext = () => {
    setActive((p) => (p === testimonials.length - 1 ? 0 : p + 1))
  }

  const setInMotionFalse = () => setInMotion(false)

  useEffect(() => {
    if (autoPlay) setInMotion(true)
    setTimeout(setInMotionFalse, 200)
    const intervalId = setInterval(() => {
      handleNext()
    }, duration)
    return () => clearInterval(intervalId)
  }, [active, autoPlay, duration])

  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (colorHasPriority) {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  } else {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? { background: `background: url(${isPreview});`, as: "div" }
      : {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
        }
  }
  const classes = [contentLayout, "testimonials"]

  const hasHeading = title || description

  // Get Text Colour
  const finalText = textColour
    ? textColour
    : lightSkin
    ? colors.midnight[400]
    : colors.tusk[100]

  return (
    <Wrapper {...wrapperProps}>
      <Section
        shape={shape}
        vSpacing={vSpacing}
        inMotion={inMotion}
        lightSkin={lightSkin}
        finalText={finalText}
        assetColours={assetColours}
        className={classes.join(" ")}
        hasHeading={hasHeading.length > 0}
      >
        <Inner>
          {title && <h2>{title}</h2>}
          {description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          <div className="testimonials-wrapper">
            <button onClick={handlePrev} className="p">
              <Icon name="arrow-right" />
            </button>
            <PoseGroup className="testimonial-pose-group">
              <Pose key={active}>
                <Testimonial
                  lightSkin={lightSkin}
                  finalText={finalText}
                  assetColours={assetColours}
                  user={testimonials[active]}
                  boxBackgroundColour={boxBackgroundColour}
                />
              </Pose>
            </PoseGroup>
            <button onClick={handleNext} className="n">
              <Icon name="arrow-right" />
            </button>
          </div>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default TestimonialsLayoutA

///////////////////////////////////////
// ⚡️ Animation
///////////////////////////////////////
const Pose = posed.div({
  enter: { opacity: 1, delay: 150, x: 0 },
  exit: { opacity: 0, x: 25 },
})

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  ${({ background }) => background}
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  color: ${({ finalText }) => finalText};
  h2,
  h4,
  p {
    color: ${({ finalText }) => finalText};
  }
  h2,
  .description {
    text-align: center;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .testimonials-wrapper {
    margin-top: ${({ hasHeading, vSpacing }) => (hasHeading ? vSpacing : "0")};
    text-align: center;
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    justify-items: center;
    align-items: center;
    grid-gap: 20px;
    .testimonial-pose-group {
      width: 100%;
    }
    button {
      padding: 15px;
      border: none;
      background: ${({ assetColours }) => getAssetColours(assetColours)};
      border-radius: ${({ shape }) => shape};
      transition: all 200ms ease-in-out;
      svg path {
        fill: ${({ assetColours }) =>
          getInverseColour(getAssetColours(assetColours))};
      }
      box-shadow: 0 10px 23px 0 rgba(0, 0, 0, 0.05);
      &:hover {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.25);
        transform: translateY(-4px);
        cursor: pointer;
      }
      &.p {
        svg {
          transform: rotate(180deg);
        }
      }
      @media (max-width: ${({ theme }) => theme.breakMedium}) {
        &.p {
          grid-column: 1;
          grid-row: 2;
          justify-self: flex-end;
        }
        &.n {
          grid-column: 2;
          grid-row: 2;
          justify-self: flex-start;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .testimonials-wrapper {
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      .testimonial-pose-group {
        grid-column: 1 / span 2;
        grid-row: 1;
      }
    }
  }
`

///////////////////////////////////////////////////////////////////////////////
// 🛠 Component
///////////////////////////////////////////////////////////////////////////////
const Testimonial = ({
  user,
  lightSkin,
  finalText,
  boxBackgroundColour,
  assetColours,
}) => {
  return (
    <Container
      lightSkin={lightSkin}
      finalText={finalText}
      assetColours={assetColours}
      className="testimonial-bg"
      boxBackgroundColour={boxBackgroundColour}
    >
      {user.avatar !== null && (
        <FluidImage
          image={user?.avatar}
          fluid={user?.avatar?.imageFile?.childImageSharp?.fluid}
          alt={user?.name}
          className="avatar"
        />
      )}
      <div
        className="testimonial"
        dangerouslySetInnerHTML={{ __html: user.comment }}
      />
      <div className="author">
        <h4>{user.name}</h4>
        <p>{user.source}</p>
      </div>
    </Container>
  )
}

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Container = styled.div`
  margin-top: 40px;
  box-shadow: 0 13px 27px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({ boxBackgroundColour, lightSkin, theme }) =>
    boxBackgroundColour
      ? boxBackgroundColour
      : lightSkin
      ? theme.colors.tusk[200]
      : theme.colors.midnight[100]};
  border-radius: 4px;
  position: relative;
  &::before {
    content: open-quote;
    color: ${({ assetColours }) => `${getAssetColours(assetColours)}1A`};
    font-size: 10rem;
    font-weight: 900;
    position: absolute;
    left: 2rem;
    top: -0.8rem;
  }
  .testimonial {
    color: ${({ finalText }) => finalText};
    padding: 0 50px 30px 50px;
    font-size: 1.1rem;
    line-height: 1.5;
    display: grid;
    align-items: center;
    position: relative;
    min-height: 200px;
  }
  .author {
    padding: 20px;
    border-top: ${({ lightSkin }) =>
      lightSkin
        ? "1px solid rgba(0, 0, 0, 0.08)"
        : "1px solid rgba(255, 255, 255, 0.05)"};
    h4 {
      color: ${({ finalText }) => finalText};
      margin: 15px 0 0 0;
      font-size: 2rem;
      text-align: center;
    }
    p {
      color: ${({ finalText }) => `${finalText}B3`};
      margin: 5px 0 15px 0;
      font-size: 1.2rem;
    }
  }
  .avatar {
    position: absolute;
    left: calc(50% - 40px);
    top: -40px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .testimonial {
      padding: 0 30px 30px 30px;
    }
    .author {
      padding: 10px;
    }
  }
`
