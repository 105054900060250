import React, { useState } from "react"
import * as Yup from "yup"
import styled, { keyframes } from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { useFormik } from "formik"
import { navigate } from "gatsby"
import cuid from "cuid"
import { useFirebase } from "../../components/Firebase/FirebaseProvider"

import Inner from "../../dsm/layout/Inner"
import {
  getSlug,
  getVerticalSpacing,
  getAssetColours,
  getInverseColour,
  getFontFamily,
} from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import Message from "../../dsm/message"
const UPLOAD_VAR = "__upload-"
///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Form = ({
  title,
  toEmails,
  formName,
  formFields,
  textColour,
  description,
  assetColours,
  contentLayout,
  verticalSpacing,
  backgroundImage,
  formFieldColours,
  submitButtonText,
  backgroundColour,
  typeOfConfirmation,
  confirmationMessage,
  triggerNotification,
  confirmationRedirectUrl,
}) => {
  const { createEnquiry, firebase } = useFirebase()
  const { lightSkin, shape, headingTypography } = getFlexiOptionsBranding()

  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if we have an image, show a background
  if (backgroundImage !== null) {
    Wrapper = WrapperImage
    wrapperProps = {
      fluid: backgroundImage.imageFile.childImageSharp.fluid,
    }
  } else {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  }
  const classes = [contentLayout, "form"]

  // Form elements
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)
  const [loading, setLoading] = useState(false)

  // FORM SET-UP
  const initialValuesOutline = {}
  const validationSchema = {}

  formFields.map((field) => {
    // get slug
    const slug = getSlug(field.label)
    // initial blank value for field
    initialValuesOutline[slug] = ""
    // see if field is required and set schema
    if (field.required && field.type !== "heading") {
      if (field.type === "email") {
        validationSchema[slug] = Yup.string()
          .email("Invalid email address")
          .required(`A valid Email address is required`)
      } else if (field.type === "select") {
        validationSchema[slug] = Yup.string().required(
          `Please select an option`
        )
      } else {
        validationSchema[slug] = Yup.string().required(`This field is required`)
      }
    }
  })

  const emails = Object.entries(toEmails).map(([key, value]) => value.email)

  const formik = useFormik({
    initialValues: { ...initialValuesOutline },
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (values) => {
      setSending(true)
      // prettier-ignore
      var fields = Object.entries(values) // replace files with google storage reference
        .reduce(
          (a, [key, val]) => [
            ...a,
            [key, values[UPLOAD_VAR + key] ? values[UPLOAD_VAR + key].url : val],
          ],[])
        .filter(([k]) => !k.includes(UPLOAD_VAR)) // remove temporary __upload fields
        .reduce((a, [k, v]) => ({ ...a, [k]: v }), {}) // convert back to object

      try {
        // Send Enquiry to Firebase
        await createEnquiry({
          fields,
          formName,
          triggerNotification,
          toEmails: emails,
        })
        // SUCCESS
        if (typeOfConfirmation === "redirect") {
          navigate(confirmationRedirectUrl)
        } else {
          setSent(true)
        }
      } catch (e) {
        console.error(e)
      }
      setSending(false)
    },
  })

  return (
    <Wrapper {...wrapperProps}>
      <Section
        shape={shape}
        lightSkin={lightSkin}
        textColour={textColour}
        className={classes.join(" ")}
        verticalSpacing={verticalSpacing}
        headingTypography={headingTypography}
        assetColour={getAssetColours(assetColours)}
      >
        <Inner>
          {title && <h2>{title}</h2>}
          {description && (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          <div className="form-wrapper">
            {sent && typeOfConfirmation === "text" ? (
              <Message type="success" description={confirmationMessage} />
            ) : (
              <form onSubmit={formik.handleSubmit}>
                {formFields &&
                  formFields.map((field, i) => {
                    const slug = getSlug(field.label)
                    return field.type === "heading" ? (
                      <h3 key={i}>{field.label}</h3>
                    ) : (
                      <FieldGroup
                        key={i}
                        isLight={formFieldColours === "light"}
                        textColour={textColour}
                        className={
                          formik.touched[slug] && formik.errors[slug]
                            ? "error"
                            : "valid"
                        }
                      >
                        <label htmlFor={slug}>
                          {field.label} {field.required && <span>*</span>}
                        </label>
                        <GetFormField
                          field={field}
                          formik={formik}
                          setLoading={setLoading}
                        />
                        {formik.touched[slug] && formik.errors[slug] ? (
                          <div className="form-error-message">
                            {formik.errors[slug]}
                          </div>
                        ) : null}
                      </FieldGroup>
                    )
                  })}
                <button
                  type="submit"
                  disabled={!formik.isValid || formik.isSubmitting || loading}
                >
                  {sending ? (
                    <Loader assetColour={getAssetColours(assetColours)} />
                  ) : (
                    submitButtonText
                  )}
                </button>
              </form>
            )}
          </div>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default Form

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
export const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ textColour }) => textColour};
  text-align: center;
  h2 {
    color: ${({ textColour }) => textColour};
    margin-top: 0;
  }
  .description {
    margin-bottom: 3rem;
  }
  &.full {
    .inner {
      max-width: calc(${({ theme }) => theme.maxWidth} + 40px);
      margin: 0 -20px;
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: 600px;
    }
  }
  .form-wrapper {
    padding: 20px 0;
  }
  h3 {
    text-align: left;
    margin: 2rem 0 0 0;
  }
  button {
    color: ${({ assetColour }) => getInverseColour(assetColour)};
    background-color: ${({ assetColour }) => assetColour};
    border: none;
    padding: 15px 35px;
    font-size: 1.15rem;
    margin: 20px 0;
    min-width: 170px;
    text-align: center;
    border-radius: ${({ shape }) => shape};
    font-family: ${({ headingTypography }) =>
      getFontFamily(headingTypography.fontFamily)};
    font-weight: ${({ headingTypography }) => headingTypography.h1.fontWeight};
    transition: all 200ms ease-in-out;
    &:disabled {
      opacity: 0.7;
      &:hover {
        cursor: not-allowed;
      }
    }
    &:hover {
      cursor: pointer;
      transform: translateY(-3px);
      border: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    button {
      width: calc(100%);
    }
  }
`
export const FieldGroup = styled.div`
  text-align: left;
  padding: 20px 0 10px 0;
  label {
    padding-bottom: 10px;
    display: block;
    color: ${({ textColour }) => textColour};
    opacity: 0.8;
    span {
      color: #ff6160;
    }
  }
  input,
  textarea {
    color: ${({ theme, isLight }) =>
      isLight ? theme.colors.midnight[100] : theme.colors.tusk[100]};
    background: ${({ theme, isLight }) =>
      isLight ? theme.colors.tusk[200] : theme.colors.midnight[200]};
    border: 1px solid
      ${({ theme, isLight }) =>
        isLight ? theme.colors.tusk[400] : theme.colors.midnight[100]};
    padding: 10px;
    border-radius: 4px;
    display: block;
    width: calc(100% - 20px);
  }
  textarea {
    resize: none;
    height: 80px;
  }
  select {
    color: ${({ theme, isLight }) =>
      isLight ? theme.colors.midnight[100] : theme.colors.tusk[100]};
    background: ${({ theme, isLight }) =>
      isLight ? theme.colors.tusk[200] : theme.colors.midnight[200]};
    border: 1px solid
      ${({ theme, isLight }) =>
        isLight ? theme.colors.tusk[400] : theme.colors.midnight[100]};
    border-radius: 4px;
    display: block;
    width: 100%;
    text-indent: 5px;
    height: 40px;
  }
  .form-error-message {
    color: #ff6160;
    font-size: 0.9rem;
    padding: 5px;
  }
  &.error {
    input,
    textarea {
      border: 1px solid #ff6160;
    }
  }
`
const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`
export const Loader = styled.div`
  border: 3px solid ${({ assetColour }) => `${getInverseColour(assetColour)}1A`};
  border-top: 3px solid
    ${({ assetColour }) => `${getInverseColour(assetColour)}4D`};
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation: ${spin} 1s linear infinite;
  display: inline-block;
  margin: 0;
  padding: 0;
`

///////////////////////////////////////////////////////////////////////////////
// 👌 Helper Components
///////////////////////////////////////////////////////////////////////////////
const GetFormField = ({ field, formik, setLoading }) => {
  const slug = getSlug(field.label)
  let output

  const props = formik.getFieldProps(slug)

  switch (field.type) {
    case "input":
      output = (
        <input
          id={slug}
          name={slug}
          type={field.type}
          {...formik.getFieldProps(slug)}
        />
      )
      break
    case "file":
      output = (
        <File
          id={slug}
          name={slug}
          type={field.type}
          formik={formik}
          setLoading={setLoading}
          {...formik.getFieldProps(slug)}
        />
      )
      break
    case "textarea":
      output = (
        <textarea id={slug} name={slug} {...formik.getFieldProps(slug)} />
      )
      break
    case "select":
      output = (
        <select
          id={slug}
          name={slug}
          {...formik.getFieldProps(slug)}
          defaultValue=""
        >
          <option disabled value="">
            -- Select --
          </option>
          {field.selectOptions !== null &&
            field.selectOptions.map((option, i) => (
              <option value={option.label} key={i}>
                {option.label}
              </option>
            ))}
          {field.showOtherOption && <option value="other" label="Other" />}
        </select>
      )
      break
    default:
      output = (
        <input
          id={slug}
          name={slug}
          type={field.type}
          {...formik.getFieldProps(slug)}
        />
      )
      break
  }
  return output
}

const File = (props) => {
  const { firebase } = useFirebase()
  return (
    <input
      type="file"
      {...props}
      onChange={(e) => {
        e.preventDefault()
        var file = e.currentTarget.files[0]
        props.setLoading(true)
        var ref = firebase.storage().ref()
        var location =
          (process.env.GATSBY_SITE_TITLE || process.env.GATSBY_SITE_URL) +
          "/" +
          cuid() +
          "-" +
          file.name

        const child = ref.child(location)
        child.put(file).then((snap) => {
          snap.ref.getDownloadURL().then((url) => {
            props.formik.setFieldValue(UPLOAD_VAR + props.id, {
              ref: snap.ref,
              url,
            })
            props.setLoading(false)
          })
        })

        return props.onChange(e)
      }}
    />
  )
}
