import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        flexisiteOptionsIntegrations {
          optionsIntegrations {
            apiKey
            clientId
            defaultListId
          }
        }
      }
    }
  `)

  return data.wpgraphql.flexisiteOptionsIntegrations.optionsIntegrations
}
