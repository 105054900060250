import React from "react"
import styled from "styled-components"

import Inner from "../../dsm/layout/Inner"
import { getVerticalSpacing } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import InnerHTML from "dangerously-set-html-content"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const HtmlEmbedBlock = ({
  html,
  contentLayout,
  verticalSpacing,
  backgroundColour,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()

  const classes = [contentLayout, "html-block"]

  return (
    <Wrapper backgroundColour={backgroundColour}>
      <Section
        lightSkin={lightSkin}
        className={classes.join(" ")}
        verticalSpacing={verticalSpacing}
        backgroundColour={backgroundColour}
      >
        <Inner>
          <InnerHTML html={html} />
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default HtmlEmbedBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
`
