import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Inner from "../../dsm/layout/Inner"
import Button from "../../dsm/buttons"
import { getVerticalSpacing } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const HeroHeaderBlock = ({
  title,
  buttons,
  headerSize,
  lineHeight,
  textColour,
  description,
  showButtons,
  contentLayout,
  verticalSpacing,
  backgroundColour,
  backgroundPosition,
  backgroundImageMobile,
  backgroundImageDesktop,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()

  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if we have an image, show a background
  if (backgroundImageDesktop?.imageFile) {
    Wrapper = WrapperImage
    const sources = [
      backgroundImageMobile?.imageFile?.childImageSharp?.fluid,
      {
        ...backgroundImageDesktop?.imageFile?.childImageSharp?.fluid,
        media: `(min-width: 769px)`,
      },
    ]
    wrapperProps = {
      fluid: sources,
      backgroundPosition: backgroundPosition,
      backgroundColour: backgroundColour,
    }
  } else if (backgroundImageDesktop?.mediaItemUrl) {
    console.log("HeroHeader: using preview")
    Wrapper = WrapperImagePreview
    wrapperProps = {
      backgroundPosition: backgroundPosition,
      background: backgroundImageDesktop?.mediaItemUrl,
    }
  } else {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  }
  const classes = [contentLayout, "hero-header-block"]

  return (
    <Wrapper {...wrapperProps}>
      <Section
        lightSkin={lightSkin}
        textColour={textColour}
        className={classes.join(" ")}
        verticalSpacing={verticalSpacing}
      >
        <Inner>
          {title && (
            <Heading
              as={headerSize}
              className="heading"
              textColour={textColour}
              lineHeight={lineHeight}
            >
              {title}
            </Heading>
          )}
          {description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          {showButtons && buttons && buttons.length > 0 && (
            <div className="button-row">
              {buttons.map((button) => (
                <Button
                  to={button.link}
                  key={button.link}
                  type={button.type}
                  icon={button.icon}
                  label={button.label}
                  isExternal={button.isExternal}
                  color={button.buttonColour || textColour}
                />
              ))}
            </div>
          )}
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default HeroHeaderBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImagePreview = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background: url(${({ background }) => background});
  background-position: ${({ backgroundPosition }) => backgroundPosition};
`
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ backgroundColour }) => backgroundColour};
  background-position: ${({ backgroundPosition }) => backgroundPosition};
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ textColour }) => textColour};
  text-align: center;
  h1 {
    font-size: 5.625rem;
    line-height: 1;
  }
  h2 {
    font-size: 4.6875rem;
    line-height: 1;
  }
  h3 {
    font-size: 3.75rem;
    line-height: 1;
  }
  h4 {
    font-size: 3rem;
    line-height: 1;
  }
  h5 {
    font-size: 2.3rem;
    line-height: 1;
  }
  .description {
    font-size: 1.3rem;
    margin: 20px 0;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  &.half-left {
    .inner .heading,
    .inner .description {
      max-width: 50%;
      text-align: left;
      margin-left: 10px;
    }
    .inner .button-row {
      max-width: 50%;
      justify-content: flex-start;
    }
  }
  &.half-right {
    .inner .heading {
      max-width: 50%;
      text-align: right;
      margin: 0 10px 0 auto;
    }
    .inner .button-row {
      max-width: 50%;
      justify-content: flex-end;
      margin: 0 0 0 auto;
    }
  }
  .button-row {
    display: flex;
    justify-content: center;
    a {
      margin: 25px 10px 0 10px;
      font-size: 1.2rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding-top: 50px;
    h1 {
      font-size: 3.5rem;
    }
    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    .description {
      font-size: 1.15rem;
    }
    &.half-left,
    &.half-right {
      .inner .heading,
      .inner .description {
        max-width: 100%;
      }
      .inner .button-row {
        max-width: 100%;
        justify-content: flex-start;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .button-row {
      display: block;
      a {
        margin: 25px 0 0 0;
      }
    }
  }
`
const Heading = styled.h3`
  color: ${({ textColour }) => textColour};
  margin-top: 0;
  line-height: ${({ lineHeight }) => lineHeight} !important;
`
