import React from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import InnerHTML from "dangerously-set-html-content"

import Inner from "../../dsm/layout/Inner"
import { getVerticalSpacing, getWrapper } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const VideoBlock = ({
  title,
  video,
  embedCode,
  embedType,
  textColour,
  description,
  aspectRatio,
  contentLayout,
  backgroundColour,
  verticalSpacing,
  backgroundImage,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()
  let Wrapper = getWrapper({ backgroundImage, backgroundColour })
  const classes = [contentLayout, "video-block"]
  const hasHeading = title || description

  return (
    <Wrapper>
      <Section
        lightSkin={lightSkin}
        textColour={textColour}
        className={classes.join(" ")}
        aspectRatio={aspectRatio}
        verticalSpacing={verticalSpacing}
        hasHeading={hasHeading.length > 0}
      >
        <Inner>
          {title && <h2>{title}</h2>}
          {description && (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          <div className="video-wrapper">
            {embedType === "oembed" ? (
              <ReactPlayer
                url={video}
                config={{
                  youtube: {
                    playerVars: { showinfo: 0 },
                  },
                }}
              />
            ) : (
              <InnerHTML html={embedCode} />
            )}
          </div>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default VideoBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ textColour }) => textColour};
  background-color: ${({ backgroundColour }) => backgroundColour};
  text-align: center;
  h2 {
    color: ${({ textColour }) => textColour};
    margin-top: 0;
  }
  .description {
    margin-bottom: 3rem;
  }
  &.full {
    .inner {
      max-width: 100%;
      margin: 0 -20px;
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .video-wrapper {
    height: 0;
    position: relative;
    margin-top: ${({ hasHeading }) => (hasHeading ? "45px" : "0")};
    padding-bottom: ${({ aspectRatio }) => `${aspectRatio}%`};
  }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`
