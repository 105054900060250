import React from "react"
import { Link } from "gatsby"
import { format, parseISO } from "date-fns"
import styled from "styled-components"

import FluidImage from "../../../components/Images/FluidImage"
import { CreateLocalLink, getColorLuminance } from "../../../utils"
import getFlexiOptionsLayouts from "../../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const PostCardLayoutA = ({ post }) => {
  const { link, title, featuredImage, excerpt, author, date, categories } = post
  const { lightSkin, shape } = getFlexiOptionsBranding()
  const {
    blogHeaderConfig,
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  return (
    <Card
      to={CreateLocalLink(link)}
      shape={shape}
      lightSkin={lightSkin}
      mainBgColor={mainBgColor}
    >
      <FluidImage image={featuredImage} withFallback />
      <div className="content">
        <h3 dangerouslySetInnerHTML={{ __html: title }} />
        <div dangerouslySetInnerHTML={{ __html: excerpt }} />
      </div>
      {(blogHeaderConfig.showAuthor !== null ||
        blogHeaderConfig.showCategories !== null) && (
        <div className="footer">
          {blogHeaderConfig.showAuthor !== null ? (
            <div className="author">
              <img
                src={author.avatar.url}
                alt={author.name}
                className="avatar"
              />
              <div>
                {author.name}
                <div className="date">
                  {format(parseISO(date), "d MMMM yyyy")}
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
          {blogHeaderConfig.showCategories !== null ? (
            <div className="categories">
              {categories.nodes.map(
                (cat, i) =>
                  i < 2 && (
                    <CategoryLabel
                      key={i}
                      shape={shape}
                      lightSkin={lightSkin}
                      mainBgColor={mainBgColor}
                    >
                      {cat.name}
                    </CategoryLabel>
                  )
              )}
              {categories.nodes.length > 2 && (
                <span>+ {categories.nodes.length - 2} More</span>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      )}
    </Card>
  )
}
export default PostCardLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Card = styled(Link)`
  text-align: left;
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? getColorLuminance(mainBgColor, 0.2)
      : lightSkin
      ? theme.colors.tusk[100]
      : theme.colors.midnight[300]};
  display: grid;
  grid-template-rows: minmax(auto, 250px) 1fr auto;
  border: none;
  box-shadow: 0 10px 23px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 15px;
  margin: 40px 0;
  h3 {
    margin: 10px 0;
    line-height: 1.1;
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.midnight[300] : theme.colors.tusk[300]};
  }
  img {
    border-radius: 2px;
  }
  .content {
    padding: 15px;
    p {
      margin: 0;
      color: ${({ theme, lightSkin }) =>
        lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[300]};
      opacity: 0.8;
    }
  }
  .footer {
    padding: 15px;
    margin: 0 -15px -15px -15px;
    border-top: 1px solid
      ${({ theme, lightSkin, mainBgColor }) =>
        mainBgColor
          ? getColorLuminance(mainBgColor, 0.4)
          : lightSkin
          ? theme.colors.tusk[200]
          : theme.colors.midnight[400]};
    background: ${({ theme, lightSkin, mainBgColor }) =>
      mainBgColor
        ? getColorLuminance(mainBgColor, 0.3)
        : lightSkin
        ? theme.colors.tusk[100]
        : theme.colors.midnight[400]};
    display: grid;
    grid-template-columns: auto auto;
    border-radius: 0 0 4px 4px;
  }
  .author {
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[400]};
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    align-items: center;
    font-weight: 600;
  }
  .date {
    font-weight: 400;
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.tusk[600] : theme.colors.tusk[500]};
    font-size: 0.85rem;
  }
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .categories {
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[600]};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }
  &:hover {
    border: none;
    transform: translateY(-4px);
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .footer {
      grid-template-columns: 1fr;
      grid-row-gap: 10px;
    }
    .categories {
      justify-content: flex-start;
    }
  }
`
const CategoryLabel = styled.span`
  background-color: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? getColorLuminance(mainBgColor)
      : lightSkin
      ? theme.colors.tusk[300]
      : theme.colors.midnight[100]};
  color: ${({ theme, lightSkin }) =>
    lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[500]};
  display: inline-block;
  padding: 10px 15px;
  border-radius: ${({ shape }) => shape};
  margin: 5px;
  text-transform: capitalize;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font-size: 0.8rem;
  }
`
