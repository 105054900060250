import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Inner from "../../../dsm/layout/Inner"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const QuoteLayoutA = ({
  text,
  author,
  alignment,
  vSpacing,
  textColour,
  iconColour,
  contentLayout,
  backgroundColour,
  backgroundImage,
  colorHasPriority,
  isPreview,
}) => {
  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (colorHasPriority) {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  } else {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? { background: `background: url(${isPreview});`, as: "div" }
      : {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
        }
  }
  const classes = [contentLayout, "quote"]

  return (
    <Wrapper {...wrapperProps}>
      <Section
        vSpacing={vSpacing}
        alignment={alignment}
        textColour={textColour}
        iconColour={iconColour}
        contentLayout={contentLayout}
        className={classes.join(" ")}
      >
        <Inner>
          <div className="quote-wrapper">
            <div className="icon">
              <h4>&ldquo;</h4>
            </div>
            <div className="content">
              <h2 dangerouslySetInnerHTML={{ __html: text }} />
              <div
                dangerouslySetInnerHTML={{ __html: author }}
                className="author"
              />
            </div>
          </div>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default QuoteLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  ${({ background }) => background}
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  color: ${({ textColour }) => textColour};
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .inner {
    display: grid;
    justify-content: ${({ alignment }) => alignment};
  }
  .quote-wrapper {
    display: grid;
    grid-template-columns: ${({ alignment }) =>
      alignment === "flex-start" ? "100px 1fr" : "1fr"};
    text-align: ${({ alignment }) =>
      alignment === "flex-start" ? "left" : "center"};
    align-content: flex-start;
    grid-gap: ${({ alignment }) => (alignment === "flex-start" ? "12px" : "0")};
    .icon h4 {
      background: ${({ iconColour }) => iconColour};
      display: inline-block;
      width: 60px;
      height: 60px;
      text-align: center;
      border-radius: 100px;
      font-size: 108px;
      margin: 0;
      font-weight: 400;
      padding: 12px 10px 8px 10px;
    }
    .content {
      h2 {
        color: ${({ textColour }) => textColour};
        margin: 0;
        line-height: 1.1;
      }
      .author {
        color: ${({ textColour }) => `${textColour}CC`};
        margin-top: 10px;
        font-size: 1.15rem;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .quote-wrapper {
      grid-template-columns: 1fr;
      grid-gap: 0;
      grid-template-rows: auto 1fr;
    }
  }
`
