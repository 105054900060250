import React from "react"
import gql from "graphql-tag"
import { getParams } from "./utils/index."

const withPagePreviewQuery = (config) => (WrappedComponent) => (props) => {
  const { pageContext } = props
  var params = getParams()

  var isAlternate =
    params &&
    (params[process.env.GATSBY_REVISION_PARAM] ||
      params[process.env.GATSBY_LATEST_PARAM])
  var getLastTrue = (list) =>
    list.reduce((a, c) => (c.condition(state) ? c : a))

  var state = { params, pageContext }
  var previewQuery = config.transformer(
    gql(getLastTrue(config.things).gqlString(state))
  )

  // var focus = getLastTrue(config.things)
  // var gqlString = focus.gqlString(state)
  // var ast = gql(gqlString)
  // var preview = config.transformer(ast)

  // console.log({ config, state, focus, gqlString, ast, preview })

  return (
    <WrappedComponent
      previewQuery={previewQuery}
      isAlternate={isAlternate}
      {...props}
    />
  )
}

export default withPagePreviewQuery
