import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Inner from "../../../dsm/layout/Inner"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import colors from "../../../dsm/colors"
import { getAssetColours } from "../../../utils"
import FluidImage from "../../../components/Images/FluidImage"

///////////////////////////////////////////////////////////////////////////////
// 🛠 Component
///////////////////////////////////////////////////////////////////////////////
const TestimonialsLayoutB = ({
  title,
  vSpacing,
  textColour,
  isPreview,
  description,
  testimonials,
  assetColours,
  contentLayout,
  backgroundImage,
  backgroundColour,
  colorHasPriority,
  boxBackgroundColour,
}) => {
  const { lightSkin, shape } = getFlexiOptionsBranding()

  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (colorHasPriority) {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  } else {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? { background: `background: url(${isPreview});`, as: "div" }
      : {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
        }
  }
  const classes = [contentLayout, "testimonials"]

  const hasHeading = title || description

  // Get Text Colour
  const finalText = textColour
    ? textColour
    : lightSkin
    ? colors.midnight[400]
    : colors.tusk[100]

  return (
    <Wrapper {...wrapperProps}>
      <Section
        vSpacing={vSpacing}
        lightSkin={lightSkin}
        finalText={finalText}
        className={classes.join(" ")}
        hasHeading={hasHeading.length > 0}
      >
        <Inner>
          {title && <h2>{title}</h2>}
          {description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          <div className={getWrapperClasses(testimonials.length)}>
            {testimonials.map((testimonial) => (
              <Testimonial
                shape={shape}
                user={testimonial}
                lightSkin={lightSkin}
                finalText={finalText}
                assetColours={assetColours}
                boxBackgroundColour={boxBackgroundColour}
              />
            ))}
          </div>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default TestimonialsLayoutB

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  ${({ background }) => background}
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  color: ${({ finalText }) => finalText};
  h2,
  h4,
  p {
    color: ${({ finalText }) => finalText};
  }
  h2,
  .description {
    text-align: center;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .testimonials-wrapper {
    margin-top: ${({ hasHeading, vSpacing }) => (hasHeading ? vSpacing : "0")};
    display: grid;
    grid-gap: 40px;
    &.columns-1 {
      grid-template-columns: 1fr;
    }
    &.columns-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.columns-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      &.columns-3 {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      &.columns-2,
      &.columns-3 {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
`

///////////////////////////////////////////////////////////////////////////////
// 🛠 Component
///////////////////////////////////////////////////////////////////////////////
const Testimonial = ({
  user,
  shape,
  lightSkin,
  finalText,
  assetColours,
  boxBackgroundColour,
}) => {
  return (
    <Container
      shape={shape}
      lightSkin={lightSkin}
      finalText={finalText}
      assetColours={assetColours}
      boxBackgroundColour={boxBackgroundColour}
    >
      <div className="author">
        {user.avatar !== null && (
          <FluidImage
            image={user?.avatar}
            fluid={user?.avatar?.imageFile?.childImageSharp?.fluid}
            alt={user?.name}
            className="avatar"
          />
        )}
        <div>
          <h4>{user.name}</h4>
          <p>{user.source}</p>
        </div>
      </div>
      <div
        className="testimonial"
        dangerouslySetInnerHTML={{ __html: user.comment }}
      />
    </Container>
  )
}

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Container = styled.div`
  box-shadow: 0 13px 27px 0 rgba(0, 0, 0, 0.15);
  background-color: ${({ boxBackgroundColour, lightSkin, theme }) =>
    boxBackgroundColour
      ? boxBackgroundColour
      : lightSkin
      ? theme.colors.tusk[200]
      : theme.colors.midnight[100]};
  border-radius: 4px;
  .testimonial {
    color: ${({ finalText }) => finalText};
    padding: 20px 40px 30px 40px;
    font-size: 1.1rem;
    line-height: 1.5;
    display: grid;
    quotes: "“" "”";
    position: relative;
  }
  .testimonial::before {
    content: open-quote;
    color: ${({ assetColours }) => getAssetColours(assetColours)};
    font-size: 2.5rem;
    line-height: 1;
    position: absolute;
    left: 1rem;
    top: 0.9rem;
  }
  .author {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-gap: 20px;
    padding: 20px;
    align-items: center;
    justify-content: flex-start;
    border-bottom: ${({ lightSkin }) =>
      lightSkin
        ? "1px solid rgba(0, 0, 0, 0.08)"
        : "1px solid rgba(255, 255, 255, 0.05)"};
    h4 {
      color: ${({ finalText }) => finalText};
      margin: 5px 0;
      font-size: 1.3rem;
    }
    p {
      color: ${({ finalText }) => `${finalText}B3`};
      margin: 5px 0;
      font-size: 1rem;
      line-height: 1.2;
    }
  }
  .avatar {
    width: 80px;
    height: 80px;
    border-radius: ${({ shape }) => shape};
  }
`
///////////////////////////////////////////////////////////////////////////////
// 👌 Helper Functions
///////////////////////////////////////////////////////////////////////////////
const getWrapperClasses = (length) => {
  let classes = ["testimonials-wrapper"]

  if (length === 1) {
    classes.push("columns-1")
  } else if (length % 3 === 0) {
    classes.push("columns-3")
  } else if (length % 2 === 0) {
    classes.push("columns-2")
  } else {
    classes.push("columns-3")
  }

  return classes.join(" ")
}
