import React, { useState } from "react"
import styled from "styled-components"
import Inner from "../../dsm/layout/Inner"
import { getVerticalSpacing, getColorLuminance, getWrapper } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import colors from "../../dsm/colors"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const FAQs = ({
  title,
  content,
  textColour,
  description,
  accentColour,
  contentLayout,
  verticalSpacing,
  backgroundImage,
  backgroundColour,
  boxBackgroundColour,
}) => {
  const { lightSkin, primaryColor, secondaryColor } = getFlexiOptionsBranding()
  let Wrapper = getWrapper({ backgroundImage, backgroundColour })

  const classes = [contentLayout, "faq-block"]

  const hasHeading = title || description

  // Get Text Colour
  const finalText = textColour
    ? textColour
    : lightSkin
    ? colors.midnight[400]
    : colors.tusk[100]

  // Get Accent Colour
  let finalAccent = primaryColor
  switch (accentColour) {
    case "primaryColor":
      finalAccent = primaryColor
      break
    case "secondaryColor":
      finalAccent = secondaryColor
      break
    case "white":
      finalAccent = "#fff"
      break
    case "black":
      finalAccent = "#000"
      break
    default:
      finalAccent = primaryColor
      break
  }

  return (
    <Wrapper>
      <Section
        lightSkin={lightSkin}
        finalText={finalText}
        className={classes.join(" ")}
        hasHeading={hasHeading.length > 0}
        verticalSpacing={verticalSpacing}
      >
        <Inner>
          {title && <h2>{title}</h2>}
          {description && (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          )}
          <div className="faq-wrapper">
            {content.map((row, i) => (
              <Question
                key={i}
                answer={row.answer}
                question={row.question}
                lightSkin={lightSkin}
                finalText={finalText}
                finalAccent={finalAccent}
                primaryColor={primaryColor}
                boxBackgroundColour={boxBackgroundColour}
              />
            ))}
          </div>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default FAQs

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ finalText }) => finalText};
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour : "rgba(0, 0, 0, 0.08)"};
  text-align: center;
  h2 {
    color: ${({ finalText }) => finalText};
    margin-top: 0;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .faq-wrapper {
    margin-top: ${({ hasHeading, verticalSpacing }) =>
      hasHeading ? getVerticalSpacing(verticalSpacing) : "0"};
  }
`

///////////////////////////////////////////////////////////////////////////////
// 🛠 Component
///////////////////////////////////////////////////////////////////////////////
const Question = ({
  question,
  answer,
  lightSkin,
  finalText,
  finalAccent,
  boxBackgroundColour,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Row
      isOpen={isOpen}
      lightSkin={lightSkin}
      finalText={finalText}
      finalAccent={finalAccent}
      boxBackgroundColour={boxBackgroundColour}
    >
      <div
        className="q"
        onClick={() => setIsOpen(!isOpen)}
        onKeyPress={() => setIsOpen(!isOpen)}
        role="button"
        tabIndex="0"
      >
        <h3>{question}</h3>
        <ButtonToggle isOpen={isOpen} finalAccent={finalAccent} />
      </div>
      <div className="a" dangerouslySetInnerHTML={{ __html: answer }} />
    </Row>
  )
}

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Row = styled.div`
  background: ${({ lightSkin, theme, boxBackgroundColour }) =>
    boxBackgroundColour
      ? boxBackgroundColour
      : lightSkin
      ? theme.colors.tusk[100]
      : theme.colors.midnight[500]};
  text-align: left;
  margin: 15px 0;
  border-radius: 4px;
  transition: all 200ms ease-in-out;
  .q {
    padding: 20px;
    border: 1px solid
      ${({ boxBackgroundColour, lightSkin, theme }) =>
        boxBackgroundColour
          ? getColorLuminance(boxBackgroundColour, -0.15)
          : lightSkin
          ? theme.colors.tusk[300]
          : theme.colors.midnight[600]};
    border-radius: ${({ isOpen }) => (isOpen ? "4px 4px 0 0" : "4px")};
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-gap: 10px;
    transition: all 200ms ease-in-out;
    h3 {
      margin: 0;
      color: ${({ finalText }) => finalText};
      line-height: 1.2;
      font-size: 18px;
      font-weight: 500;
      transition: all 200ms ease-in-out;
    }
    &:hover {
      border: 1px solid ${({ finalAccent }) => finalAccent};
      cursor: pointer;
      h3 {
        color: ${({ finalAccent }) => finalAccent};
      }
    }
  }
  .a {
    color: ${({ finalText }) => finalText};
    border: 1px solid
      ${({ boxBackgroundColour, lightSkin, theme }) =>
        boxBackgroundColour
          ? getColorLuminance(boxBackgroundColour, -0.15)
          : lightSkin
          ? theme.colors.tusk[300]
          : theme.colors.midnight[600]};
    border-radius: 0 0 4px 4px;
    border-top: none;
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    padding: 5px 20px 20px 20px;
    p {
      margin: 15px 0 0 0;
    }
    a {
      color: ${({ finalText }) => finalText};
      border-color: ${({ finalText }) => finalText};
    }
  }
`

///////////////////////////////////////////////////////////////////////////////
// 🛠 Component
///////////////////////////////////////////////////////////////////////////////
const ButtonToggle = ({ isOpen, finalAccent }) => (
  <Button isOpen={isOpen} finalAccent={finalAccent}>
    <div className="icon">
      <span />
      <span />
    </div>
  </Button>
)

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Button = styled.button`
  display: block;
  border: none;
  background: none;
  .icon {
    position: relative;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 18px;
      background: ${({ finalAccent }) => finalAccent};
      border-radius: 2px;
      left: 0px;
      top: 0px;
      transition: all 200ms ease-in-out;
    }
    span:nth-child(1) {
      transform: rotate(0deg);
    }
    span:nth-child(2) {
      transform: rotate(${({ isOpen }) => (isOpen ? "0deg" : "90deg")});
    }
  }
  &:focus {
    outline: none;
  }
`
