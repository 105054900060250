import React from "react"

import FAQ from "./FAQ"
import Form from "./Form"
import Stats from "./Stats"
import Video from "./Video"
import Quote from "./Quote"
import Slider from "./Slider"
import Divider from "./Divider"
import CountdownTimer from "./CountdownTimer"
import Gallery from "./Gallery"
import Benefits from "./Benefits"
import BlogList from "./BlogList"
import LogoWall from "./LogoWall"
import TextBlock from "./TextBlock"
import ButtonRow from "./ButtonRow"
import HtmlEmbed from "./HtmlEmbed"
import HeroHeader from "./HeroHeader"
import PageHeader from "./PageHeader"
import ContactInfo from "./ContactInfo"
import Testimonials from "./Testimonials"
import NewsletterBlock from "./NewsletterBlock"

const AllLayouts = ({ layoutData, crumbs }) => {
  const layoutType = layoutData.fieldGroupName

  /* Default component */
  const Default = () => (
    <div>
      In AllLayouts the mapping of this component is missing: {layoutType}
    </div>
  )

  /* Mapping the fieldGroupName(s) to our components */
  const layouts = {
    page_default: Default,
    page_Pagebuilder_Layouts_CountdownTimer: CountdownTimer,
    page_Pagebuilder_Layouts_Faq: FAQ,
    page_Pagebuilder_Layouts_Form: Form,
    page_Pagebuilder_Layouts_Stats: Stats,
    page_Pagebuilder_Layouts_Video: Video,
    page_Pagebuilder_Layouts_Quote: Quote,
    page_Pagebuilder_Layouts_Slider: Slider,
    page_Pagebuilder_Layouts_Divider: Divider,
    page_Pagebuilder_Layouts_Gallery: Gallery,
    page_Pagebuilder_Layouts_Benefits: Benefits,
    page_Pagebuilder_Layouts_BlogList: BlogList,
    page_Pagebuilder_Layouts_LogoWall: LogoWall,
    page_Pagebuilder_Layouts_TextBlock: TextBlock,
    page_Pagebuilder_Layouts_HtmlEmbed: HtmlEmbed,
    page_Pagebuilder_Layouts_ButtonRow: ButtonRow,
    page_Pagebuilder_Layouts_HeroHeader: HeroHeader,
    page_Pagebuilder_Layouts_PageHeader: PageHeader,
    page_Pagebuilder_Layouts_ContactInfo: ContactInfo,
    page_Pagebuilder_Layouts_Testimonials: Testimonials,
    page_Pagebuilder_Layouts_NewsletterSignUp: NewsletterBlock,
  }

  /* If layout type is not existing in our mapping, it shows our Default instead. */
  const ComponentTag = layouts[layoutType]
    ? layouts[layoutType]
    : layouts["page_default"]

  let newProps = {}
  if (layoutType === "page_Pagebuilder_Layouts_PageHeader") {
    newProps = { ...layoutData, crumbs }
  } else {
    newProps = { ...layoutData }
  }

  return <ComponentTag {...newProps} />
}

export default AllLayouts
