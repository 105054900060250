import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Inner from "../../../dsm/layout/Inner"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import colors from "../../../dsm/colors"
import { getAssetColours } from "../../../utils"
import FluidImage from "../../../components/Images/FluidImage"

///////////////////////////////////////////////////////////////////////////////
// 🛠 Component
///////////////////////////////////////////////////////////////////////////////
const TestimonialsLayoutC = ({
  title,
  vSpacing,
  textColour,
  isPreview,
  description,
  testimonials,
  assetColours,
  contentLayout,
  backgroundImage,
  backgroundColour,
  colorHasPriority,
}) => {
  const { lightSkin, shape } = getFlexiOptionsBranding()

  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (colorHasPriority) {
    wrapperProps = isPreview
      ? { background: `background: url(${isPreview});`, as: "div" }
      : {
          backgroundColour: backgroundColour,
        }
  } else {
    Wrapper = WrapperImage
    wrapperProps = {
      fluid: backgroundImage.imageFile.childImageSharp.fluid,
    }
  }
  const classes = [contentLayout, "testimonials"]

  const hasHeading = title || description

  // Get Text Colour
  const finalText = textColour
    ? textColour
    : lightSkin
    ? colors.midnight[400]
    : colors.tusk[100]

  return (
    <Wrapper {...wrapperProps}>
      <Section
        shape={shape}
        vSpacing={vSpacing}
        lightSkin={lightSkin}
        finalText={finalText}
        className={classes.join(" ")}
        hasHeading={hasHeading.length > 0}
      >
        <Inner>
          {title && <h2>{title}</h2>}
          {description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          <div className="testimonials-wrapper">
            {testimonials.map((testimonial) => (
              <Testimonial
                shape={shape}
                user={testimonial}
                lightSkin={lightSkin}
                finalText={finalText}
                assetColours={assetColours}
              />
            ))}
          </div>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default TestimonialsLayoutC

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  ${({ background }) => background};
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  color: ${({ finalText }) => finalText};
  h2,
  h4,
  p {
    color: ${({ finalText }) => finalText};
  }
  h2,
  .description {
    text-align: center;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .testimonials-wrapper {
    margin-top: ${({ hasHeading, vSpacing }) => (hasHeading ? vSpacing : "0")};
  }
`

///////////////////////////////////////////////////////////////////////////////
// 🛠 Component
///////////////////////////////////////////////////////////////////////////////
const Testimonial = ({ user, shape, lightSkin, finalText, assetColours }) => {
  return (
    <Container
      shape={shape}
      lightSkin={lightSkin}
      finalText={finalText}
      assetColours={assetColours}
    >
      <div className="author">
        {user.avatar !== null && (
          <FluidImage
            image={user?.avatar}
            fluid={user?.avatar?.imageFile?.childImageSharp?.fluid}
            alt={user?.name}
            className="avatar"
          />
        )}
      </div>
      <div className="testimonial">
        <div>
          <h4>{user.name}</h4>
          <p>{user.source}</p>
        </div>
        <div
          className="comment"
          dangerouslySetInnerHTML={{ __html: user.comment }}
        />
      </div>
    </Container>
  )
}

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Container = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-gap: 50px;
  align-items: flex-start;
  padding: 30px 0;
  border-bottom: ${({ lightSkin }) =>
    lightSkin
      ? "1px solid rgba(0, 0, 0, 0.08)"
      : "1px solid rgba(255, 255, 255, 0.05)"};
  .testimonial {
    color: ${({ finalText }) => finalText};
    font-size: 1.1rem;
    line-height: 1.5;
    h4 {
      color: ${({ finalText }) => finalText};
      margin: 0;
      font-size: 1.3rem;
    }
    p {
      color: ${({ finalText }) => `${finalText}B3`};
      margin: 0;
      font-size: 1rem;
    }
    .comment {
      padding-top: 10px;
      quotes: "“" "”" "‘" "’";
      position: relative;
    }
    .comment::before {
      content: open-quote;
      color: ${({ assetColours }) => getAssetColours(assetColours)};
      font-size: 2.5rem;
      line-height: 1;
      position: absolute;
      left: -1.2rem;
      top: 0.3rem;
    }
    .comment::after {
      content: close-quote;
      color: ${({ assetColours }) => getAssetColours(assetColours)};
      font-size: 2.5rem;
      line-height: 1;
      position: absolute;
      bottom: -13px;
      padding-left: 5px;
    }
  }
  .avatar {
    width: 130px;
    height: 130px;
    border-radius: ${({ shape }) => shape};
  }
`
