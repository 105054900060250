import React from "react"

import ContactInfoLayoutA from "./layouts/LayoutA"
import ContactInfoLayoutB from "./layouts/LayoutB"
import { getVerticalSpacing } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Benefits = ({
  title,
  layout,
  headerSize,
  textColour,
  description,
  verticalSpacing,
  backgroundColour,
  backgroundImage,
}) => {
  const colorHasPriority = backgroundImage === null
  const isPreview = backgroundImage?.mediaItemUrl
  // Swap out Layout
  let Layout = ContactInfoLayoutA
  switch (layout) {
    case "a":
      Layout = ContactInfoLayoutA
      break
    case "b":
      Layout = ContactInfoLayoutB
      break
    default:
      Layout = ContactInfoLayoutA
      break
  }

  return (
    <Layout
      isPreview={isPreview}
      title={title}
      headerSize={headerSize}
      textColour={textColour}
      description={description}
      backgroundImage={backgroundImage}
      backgroundColour={backgroundColour}
      colorHasPriority={colorHasPriority}
      vSpacing={getVerticalSpacing(verticalSpacing)}
    />
  )
}
export default Benefits
