import React from "react"

import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"
import QuoteLayoutA from "./layouts/LayoutA"
import QuoteLayoutB from "./layouts/LayoutB"
import { getVerticalSpacing } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Quote = ({
  text,
  author,
  layout,
  alignment,
  textColour,
  contentLayout,
  quoteIconColour,
  verticalSpacing,
  backgroundColour,
  backgroundImage,
}) => {
  const { quoteConfig } = getFlexiOptionsLayouts()
  const isPreview = backgroundImage?.mediaItemUrl
  // Does this page override layout? Fallback to Global Setting
  const FinalLayout = layout || quoteConfig.layout
  const FinalAlignment = alignment || quoteConfig.alignment
  const FinalContentLayout = contentLayout || quoteConfig.contentLayout
  const FinalVSpacing = verticalSpacing || quoteConfig.verticalSpacing
  const FinalBackgroundColour = backgroundColour || quoteConfig.backgroundColour
  const FinalTextColour = textColour || quoteConfig.textColour
  const FinalIconColour = quoteIconColour || quoteConfig.quoteIconColour
  const FinalBgImg = backgroundImage || quoteConfig.backgroundImage

  const colorHasPriority =
    backgroundColour || quoteConfig.backgroundImage === null

  // Swap out Layout
  let Layout = QuoteLayoutA
  switch (FinalLayout) {
    case "a":
      Layout = QuoteLayoutA
      break
    case "b":
      Layout = QuoteLayoutB
      break
    default:
      Layout = QuoteLayoutA
      break
  }

  return (
    <Layout
      text={text}
      author={author}
      isPreview={isPreview}
      quoteConfig={quoteConfig}
      alignment={FinalAlignment}
      backgroundImage={FinalBgImg}
      textColour={FinalTextColour}
      iconColour={FinalIconColour}
      contentLayout={FinalContentLayout}
      colorHasPriority={colorHasPriority}
      backgroundColour={FinalBackgroundColour}
      vSpacing={getVerticalSpacing(FinalVSpacing)}
    />
  )
}
export default Quote
