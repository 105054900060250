function replaceNodes(nodesToReplace, replaceValue, ast) {
  var items = nodesToReplace.reduce((a, c) => ({ ...a, [c]: true }), {})

  function process(node) {
    var { selectionSet } = node
    if (!selectionSet) return node
    var name = node?.name?.value

    if (items[name]) {
      return { ...node, selectionSet: replaceValue }
    }

    var { selections } = selectionSet

    return {
      ...node,
      selectionSet: {
        ...selectionSet,
        selections: selections.map(process),
      },
    }
  }

  ast.definitions = ast.definitions.map(process)
  return ast
}

export default replaceNodes
