import React from "react"
import styled from "styled-components"
import Inner from "../../dsm/layout/Inner"
import { getVerticalSpacing, getWrapper } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const StatsBlock = ({
  stats,
  textColour,
  contentLayout,
  verticalSpacing,
  backgroundColour,
  backgroundImage,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()
  let Wrapper = getWrapper({ backgroundImage, backgroundColour })

  const classes = [contentLayout, "stats-block"]

  return (
    <Wrapper>
      <Section
        lightSkin={lightSkin}
        textColour={textColour}
        className={classes.join(" ")}
        verticalSpacing={verticalSpacing}
        columns={stats.length}
      >
        <Inner>
          {stats.map((stat, i) => (
            <div key={i}>
              <h2>{stat.number}</h2>
              <p dangerouslySetInnerHTML={{ __html: stat.description }} />
            </div>
          ))}
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default StatsBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ textColour }) => textColour};
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour : "rgba(0, 0, 0, 0.08)"};
  text-align: center;
  h2 {
    color: ${({ textColour }) => textColour};
    margin-top: 0;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .inner {
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    grid-gap: 40px;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .inner {
      grid-template-columns: repeat(
        ${({ columns }) => (columns === 4 ? "2" : "1")},
        1fr
      );
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .inner {
      grid-template-columns: 1fr;
    }
  }
`
