import React from "react"
import styled from "styled-components"

import Icon from "../icons/icon"
import colors from "../colors"

const Message = ({ type, heading, description }) => (
  <Wrapper className={type} colors={colors}>
    {type === "info" && <Icon name="info" hue={type} shade="600" />}
    {type === "danger" && <Icon name="exclamation" hue={type} shade="600" />}
    {type === "warn" && <Icon name="exclamation" hue={type} shade="600" />}
    {type === "success" && <Icon name="check" hue={type} shade="600" />}
    <div>
      {heading && <h3>{heading}</h3>}
      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
    </div>
  </Wrapper>
)

export default Message

const Wrapper = styled.div`
  border-radius: 12px;
  background-color: #ddd;
  border: 1px solid #ccc;
  padding: 15px 15px;
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-gap: 15px;
  justify-content: center;
  align-items: center;
  text-align: left;
  .icon {
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    width: 60px;
    height: 60px;
  }
  &.info {
    color: ${({ colors }) => colors.info[600]};
    background-color: ${({ colors }) => colors.info[200]};
    border: 1px solid ${({ colors }) => colors.info[400]};
    .icon {
      background-color: ${({ colors }) => colors.info[300]};
    }
  }
  &.danger {
    color: ${({ colors }) => colors.danger[600]};
    background-color: ${({ colors }) => colors.danger[200]};
    border: 1px solid ${({ colors }) => colors.danger[400]};
    .icon {
      background-color: ${({ colors }) => colors.danger[300]};
    }
  }
  &.warn {
    color: ${({ colors }) => colors.warn[600]};
    background-color: ${({ colors }) => colors.warn[200]};
    border: 1px solid ${({ colors }) => colors.warn[400]};
    .icon {
      background-color: ${({ colors }) => colors.warn[300]};
    }
  }
  &.success {
    color: ${({ colors }) => colors.success[600]};
    background-color: ${({ colors }) => colors.success[200]};
    border: 1px solid ${({ colors }) => colors.success[400]};
    .icon {
      background-color: ${({ colors }) => colors.success[300]};
    }
  }
`
