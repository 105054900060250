import React from "react"
import styled from "styled-components"
import Inner from "../../../dsm/layout/Inner"
import Button from "../../../dsm/buttons"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import { getInverseColour, getWrapper } from "../../../utils"
import FluidImage from "../../../components/Images/FluidImage"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const BenefitsLayoutA = ({
  inBox,
  title,
  gridGap,
  benefits,
  textColour,
  itemsPerRow,
  description,
  contentLayout,
  backgroundImage,
  backgroundColour,
  colorHasPriority,
  vSpacing,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()
  let Wrapper = getWrapper({ backgroundImage, backgroundColour })
  const classes = [contentLayout, "benefits"]

  const hasHeading = title || description

  return (
    <Wrapper>
      <Section
        inBox={inBox}
        gridGap={gridGap}
        vSpacing={vSpacing}
        lightSkin={lightSkin}
        textColour={textColour}
        itemsPerRow={itemsPerRow}
        className={classes.join(" ")}
        hasHeading={hasHeading.length > 0}
      >
        <Inner>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
          {description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          {benefits && (
            <div className="benefits-wrapper">
              {benefits.map((benefit, i) => (
                <div className="benefit" key={i}>
                  {benefit.image && (
                    <FluidImage
                      image={benefit.image}
                      fluid={benefit.image?.imageFile?.childImageSharp.fluid}
                      alt={benefit.image?.altText}
                    />
                  )}
                  {benefit.title && <h4>{benefit.title}</h4>}
                  {benefit.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: benefit.description,
                      }}
                    />
                  )}
                  {benefit.showButton && (
                    <div className="button-row">
                      <Button
                        to={benefit.button.link}
                        label={benefit.button.label}
                        isExternal={benefit.button.isExternal}
                        type={benefit.button.type}
                        color={benefit.button.buttonColour || textColour}
                        icon={benefit.button.icon}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default BenefitsLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const Section = styled.section`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  color: ${({ textColour }) => textColour};
  h2,
  h4,
  p {
    color: ${({ textColour }) => textColour};
  }
  h2,
  .description {
    text-align: center;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .benefits-wrapper {
    margin-top: ${({ hasHeading }) => (hasHeading ? "45px" : "0")};
    display: grid;
    grid-template-columns: repeat(${({ itemsPerRow }) => itemsPerRow}, 1fr);
    grid-gap: ${({ gridGap }) => gridGap};
    .benefit {
      .gatsby-image-wrapper {
        margin: 10px 0 30px 0;
        img {
          object-fit: scale-down !important;
        }
      }
      text-align: center;
      h4 {
        margin: 0.5rem 0;
      }
      padding: ${({ inBox }) => (inBox ? "25px" : "0")};
      border: ${({ inBox }) => (inBox ? "1px solid rgba(0,0,0,0.1)" : "0")};
      border-color: ${({ inBox, textColour, theme, lightSkin }) =>
        inBox
          ? textColour
            ? `${textColour}1A`
            : lightSkin
            ? theme.colors.tusk[100]
            : theme.colors.midnight[100]
          : null};
      border-radius: 8px;
      background: ${({ inBox, textColour, theme, lightSkin }) =>
        inBox
          ? textColour
            ? `${getInverseColour(textColour)}33`
            : lightSkin
            ? theme.colors.tusk[200]
            : theme.colors.midnight[200]
          : null};
      .button-row {
        display: flex;
        justify-content: center;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .benefits-wrapper {
      grid-template-columns: 1fr;
    }
  }
`
