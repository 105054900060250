import React from "react"
import Layout from "../../dsm/layout"
import SEO from "../../components/shared/SEO"
import AllLayouts from "../../layouts/AllLayouts"
import config from "../../components/WPLiveGatsby/config/page-revisions.config"
import withWPRevisionQuery from "../../components/WPLiveGatsby/withWPRevisionQuery"
import useWPRevision from "../../components/WPLiveGatsby/useWPRevision"
///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Page = (props) => {
  const { pageContext, location, previewQuery } = props
  const {
    page: { seo, pageBuilder },
    breadcrumb: { crumbs },
  } = pageContext

  var { revision } = useWPRevision(previewQuery)

  var revisionLayouts = revision ? revision?.pageBuilder?.layouts : null

  const layouts = revisionLayouts || pageBuilder.layouts || []

  return (
    <Layout location={location}>
      <SEO seo={seo} />
      {layouts.map((layout, index) => {
        return <AllLayouts key={index} layoutData={layout} crumbs={crumbs} />
      })}
    </Layout>
  )
}
export default withWPRevisionQuery(config)(Page)
