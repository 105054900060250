import React from "react"
import styled from "styled-components"
import colors from "../../dsm/colors"
import Inner from "../../dsm/layout/Inner"
import { getVerticalSpacing, getWrapper } from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import { SRLWrapper } from "simple-react-lightbox"
import FluidImage from "../../components/Images/FluidImage"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Gallery = ({
  title,
  images,
  textColour,
  description,
  imagesPerRow,
  enablePanzoom,
  contentLayout,
  showThumbnails,
  verticalSpacing,
  backgroundImage,
  backgroundColour,
  showDownloadButton,
}) => {
  const { lightSkin, primaryColor } = getFlexiOptionsBranding()
  let Wrapper = getWrapper({ backgroundImage, backgroundColour })

  const classes = [contentLayout, "gallery-block"]

  const hasHeading = title || description

  // Get Text Colour
  const finalText = textColour
    ? textColour
    : lightSkin
    ? colors.midnight[400]
    : colors.tusk[100]

  const galleryOptions = {
    buttonsBackgroundColor: primaryColor,
    showDownloadButton: showDownloadButton,
    showThumbnails: showThumbnails,
    enablePanzoom: enablePanzoom,
  }

  return (
    <Wrapper>
      <Section
        lightSkin={lightSkin}
        finalText={finalText}
        className={classes.join(" ")}
        hasHeading={hasHeading.length > 0}
        imagesPerRow={imagesPerRow}
        verticalSpacing={verticalSpacing}
      >
        <Inner>
          {title && <h2>{title}</h2>}
          {description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
          <SRLWrapper options={galleryOptions}>
            <div className="gallery-wrapper">
              {images &&
                images.map((img, i) => (
                  <div key={i} className="image">
                    <FluidImage
                      image={img}
                      alt={img?.altText}
                      fluid={img?.imageFile?.childImageSharp.fluid}
                    />
                    {img.altText && (
                      <div
                        dangerouslySetInnerHTML={{ __html: img.altText }}
                        className="caption"
                      />
                    )}
                  </div>
                ))}
            </div>
          </SRLWrapper>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default Gallery

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  color: ${({ finalText }) => finalText};
  background-color: ${({ backgroundColour }) =>
    backgroundColour ? backgroundColour : "rgba(0, 0, 0, 0.08)"};
  text-align: center;
  h2 {
    color: ${({ finalText }) => finalText};
    margin-top: 0;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .gallery-wrapper {
    margin-top: ${({ hasHeading, verticalSpacing }) =>
      hasHeading ? getVerticalSpacing(verticalSpacing) : "0"};
    display: grid;
    grid-template-columns: ${({ imagesPerRow }) =>
      `repeat(${imagesPerRow},1fr)`};
    grid-gap: 45px;
    align-items: flex-start;
    justify-content: center;
    .image {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      .caption {
        font-size: 0.9rem;
        font-style: italic;
        p {
          margin: 0.8rem 0 1.6rem 0;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
    /* 1024 */
    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      grid-template-columns: ${({ imagesPerRow }) =>
        imagesPerRow > 5
          ? "repeat(3,1fr)"
          : imagesPerRow > 2
          ? "repeat(2,1fr)"
          : "1fr"};
    }
    /* 768 */
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      grid-template-columns: ${({ imagesPerRow }) =>
        imagesPerRow > 3 ? "repeat(2,1fr)" : "1fr"};
    }
    /* 480 */
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      grid-template-columns: 1fr;
    }
  }
`
