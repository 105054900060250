import React from "react"

import BenefitsLayoutA from "./layouts/LayoutA"
import BenefitsLayoutB from "./layouts/LayoutB"
import BenefitsLayoutC from "./layouts/LayoutC"
import { getVerticalSpacing } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Benefits = ({
  inBox,
  title,
  layout,
  gridGap,
  iconSize,
  benefits,
  textColour,
  clownPants,
  itemsPerRow,
  description,
  contentLayout,
  verticalSpacing,
  backgroundColour,
  backgroundImage,
}) => {
  const colorHasPriority = backgroundImage === null

  // Swap out Layout
  let Layout = BenefitsLayoutA
  switch (layout) {
    case "a":
      Layout = BenefitsLayoutA
      break
    case "b":
      Layout = BenefitsLayoutB
      break
    case "c":
      Layout = BenefitsLayoutC
      break
    default:
      Layout = BenefitsLayoutA
      break
  }

  return (
    <Layout
      title={title}
      inBox={inBox}
      gridGap={gridGap}
      iconSize={iconSize}
      benefits={benefits}
      clownPants={clownPants}
      textColour={textColour}
      itemsPerRow={itemsPerRow}
      description={description}
      contentLayout={contentLayout}
      backgroundImage={backgroundImage}
      backgroundColour={backgroundColour}
      colorHasPriority={colorHasPriority}
      vSpacing={getVerticalSpacing(verticalSpacing)}
    />
  )
}
export default Benefits
