import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Inner from "../../dsm/layout/Inner"
import {
  getVerticalSpacing,
  getInverseColour,
  getFontFamily,
  getAssetColours,
} from "../../utils"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import getFlexiOptionsIntegrations from "../../options/getFlexiOptionsIntegrations"
import { FieldGroup, Loader } from "../Form/Form"
import Message from "../../dsm/message"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const NewsletterBlock = (props) => {
  var {
    additionalFields,
    textColour,
    contentLayout,
    verticalSpacing,
    backgroundColour,
    backgroundImage,
    buttonColour,
    inputStyle,
    listId,
  } = props
  const { lightSkin, shape, headingTypography } = getFlexiOptionsBranding()
  const { apiKey, defaultListId } = getFlexiOptionsIntegrations()
  const isPreview = backgroundImage?.mediaItemUrl
  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if we have an image, show a background
  if (backgroundImage !== null) {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? {
          background: `background: url(${isPreview});`,
          as: "div",
          backgroundColour: backgroundColour,
        }
      : {
          fluid: backgroundImage.imageFile?.childImageSharp?.fluid,
          backgroundColour: backgroundColour,
        }
  } else {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  }
  const classes = [contentLayout, "text-block"]
  const fields = additionalFields.concat("Email Address")

  var INITIAL = fields.reduce((a, c) => ({ ...a, [c]: "" }), { loading: false })

  const [state, setState] = React.useState(INITIAL)

  const isLight = inputStyle === "light"
  const assetColour = getAssetColours(buttonColour)
  return (
    <Wrapper {...wrapperProps}>
      <Section
        {...{
          isLight,
          shape,
          lightSkin,
          headingTypography,
          assetColour,
          textColour,
          verticalSpacing,
          backgroundColour,
        }}
        className={classes.join(" ")}
      >
        <Inner>
          <Flex horizontal={contentLayout === "Horizontal"}>
            {state.success ? (
              <Message description="Thanks for subscribing" type="success" />
            ) : (
              fields.map((field) => (
                <FieldGroup {...{ textColour, isLight }}>
                  <input
                    value={state[field]}
                    placeholder={field}
                    key={field}
                    onChange={({ target }) =>
                      setState((p) => ({ ...p, [field]: target.value }))
                    }
                  />
                </FieldGroup>
              ))
            )}
            <button
              onClick={() => {
                setState((p) => ({ ...p, loading: true }))
                var email = Object.entries(state).find(([key]) =>
                  key.toLowerCase().includes("email")
                )[1]
                var skip = false
                var params = Object.entries({
                  listID: listId || defaultListId,
                  apiKey,
                  email,
                })

                params.forEach(
                  ([key, value]) =>
                    !value.length && (skip = !alert("Missing " + key))
                )

                if (!skip) {
                  var proxy = "https://cors-anywhere.herokuapp.com/"
                  var endpoint =
                    "https://us-central1-flexisite-prod.cloudfunctions.net/subscribeToList"
                  var query = "?" + params.map(([k, v]) => `${k}=${v}`).join`&`
                  var url = proxy + endpoint + query

                  fetch(url).then(() => setState({ ...INITIAL, success: true }))
                }
              }}
            >
              {state.loading ? (
                <Loader assetColour={getAssetColours("custom", assetColour)} />
              ) : (
                "Subscribe"
              )}
            </button>
          </Flex>
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default NewsletterBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Flex = styled.div`
  display: flex;
  flex-direction: ${({ horizontal: h }) => (h ? "row" : "column")};
  flex-wrap: wrap;
  ${({ horizontal: h }) =>
    h
      ? "flex-direction: row;justify-content: space-between;align-items: baseline;"
      : ""};
`

const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: ${({ backgroundColour }) => backgroundColour};
  ${({ background }) => background}
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ verticalSpacing }) =>
    `${getVerticalSpacing(verticalSpacing)} 0`};
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ textColour }) => textColour};
  }
  p {
    margin-top: 0;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  &.boxed-left,
  &.boxed-right {
    .content-container {
      width: 40%;
      background-color: ${({ textColour, lightSkin, theme }) =>
        textColour
          ? `${getInverseColour(textColour)}40`
          : lightSkin
          ? theme.colors.tusk[100]
          : theme.colors.midnight[100]};

      padding: 10px 25px;
      border-radius: 8px;
    }
  }
  &.boxed-left {
    .content-container {
      margin-left: 0;
    }
  }
  &.boxed-right {
    .content-container {
      margin-right: 0;
      margin-left: auto;
    }
  }

  img {
    width: auto;
  }
  .aligncenter {
    margin: 0 auto;
    display: block;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    &.boxed-left,
    &.boxed-right {
      .content-container {
        width: calc(100% - 50px);
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
  }
  button {
    color: ${({ assetColour }) => getInverseColour(assetColour)};
    background-color: ${({ assetColour }) => assetColour};
    border: none;
    padding: 15px 35px;
    font-size: 1.15rem;
    margin: 20px 0;
    min-width: 170px;
    text-align: center;
    border-radius: ${({ shape }) => shape};
    font-family: ${({ headingTypography }) =>
      getFontFamily(headingTypography.fontFamily)};
    font-weight: ${({ headingTypography }) => headingTypography.h1.fontWeight};
    transition: all 200ms ease-in-out;
    &:disabled {
      opacity: 0.7;
      &:hover {
        cursor: not-allowed;
      }
    }
    &:hover {
      cursor: pointer;
      transform: translateY(-3px);
      border: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    button {
      width: calc(100%);
    }
  }
`
