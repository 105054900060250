import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import InnerHTML from "dangerously-set-html-content"
import Inner from "../../../dsm/layout/Inner"
import Button from "../../../dsm/buttons"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import { getColorLuminance, getWrapper } from "../../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const BenefitsLayoutC = ({
  title,
  benefits,
  vSpacing,
  textColour,
  clownPants,
  description,
  contentLayout,
  backgroundImage,
  backgroundColour,
  colorHasPriority,
}) => {
  const { lightSkin } = getFlexiOptionsBranding()
  let Wrapper = getWrapper({ backgroundImage, backgroundColour })

  const classes = [contentLayout, "benefits"]

  const hasHeading = title || description

  return (
    <Wrapper>
      <Section
        vSpacing={vSpacing}
        lightSkin={lightSkin}
        clownPants={clownPants}
        textColour={textColour}
        className={classes.join(" ")}
        hasHeading={hasHeading.length > 0}
        backgroundColour={backgroundColour}
        colorHasPriority={colorHasPriority}
      >
        <Inner>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
          {description && (
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              className="description"
            />
          )}
        </Inner>
        {benefits && (
          <div className="benefits-wrapper">
            {benefits.map((benefit, i) => (
              <div
                className={`benefit ${benefit.image ? "cols-2" : "cols-1"} ${
                  i % 2 ? "odd" : "even"
                }`}
                key={i}
              >
                <Inner>
                  {benefit.image && (
                    <div className="image">
                      {benefit?.image?.mediaItemUrl ? (
                        <img
                          src={benefit?.image?.mediaItemUrl}
                          alt={benefit?.image?.altText}
                        />
                      ) : benefit?.image?.imageFile?.childImageSharp?.fluid ? (
                        <Img
                          fluid={
                            benefit?.image?.imageFile?.childImageSharp?.fluid
                          }
                          alt={benefit?.image?.altText}
                        />
                      ) : null}
                    </div>
                  )}
                  <div className="content">
                    {benefit.title && <h4>{benefit.title}</h4>}
                    {benefit.description && (
                      <InnerHTML
                        html={benefit.description}
                        className="content-container"
                      />
                    )}
                    {benefit.showButton && (
                      <Button
                        to={benefit.button.link}
                        label={benefit.button.label}
                        isExternal={benefit.button.isExternal}
                        type={benefit.button.type}
                        color={benefit.button.buttonColour || textColour}
                        icon="arrow-right"
                      />
                    )}
                  </div>
                </Inner>
              </div>
            ))}
          </div>
        )}
      </Section>
    </Wrapper>
  )
}
export default BenefitsLayoutC

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const Section = styled.section`
  padding: ${({ vSpacing, hasHeading }) =>
    hasHeading ? `${vSpacing} 0 0 0` : `0`};
  color: ${({ textColour }) => textColour};
  h2,
  h4,
  p {
    color: ${({ textColour }) => textColour};
  }
  h2,
  .description {
    text-align: center;
  }
  &.full {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
    }
  }
  &.skinny-heading {
    h2,
    .description {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.skinny {
    .inner {
      max-width: ${({ theme }) => theme.maxWidthSkinny};
    }
  }
  .benefits-wrapper {
    margin-top: ${({ hasHeading }) => (hasHeading ? "90px" : "0")};
    .benefit {
      padding: ${({ vSpacing }) => `${vSpacing} 0`};
      .inner {
        display: grid;
        align-items: center;
      }
      &.cols-1 {
        .inner {
          grid-template-columns: 1fr;
        }
      }
      &.cols-2.even {
        .inner {
          grid-gap: 45px;
          grid-template-columns: ${({ clownPants }) =>
            clownPants ? " 1fr 1.2fr" : " 1.2fr 1fr"};
        }
      }
      &.cols-2.odd {
        .inner {
          grid-gap: 45px;
          grid-template-columns: ${({ clownPants }) =>
            clownPants ? " 1.2fr 1fr" : " 1.2fr 1fr"};
        }
        .image {
          grid-row: 1;
          grid-column: ${({ clownPants }) => (clownPants ? "2" : "1")};
        }
        .content {
          grid-row: 1;
          grid-column: ${({ clownPants }) => (clownPants ? "1" : "2")};
        }
      }
      h4,
      p {
        margin: 0 0 1rem 0;
      }
      &.even {
        background: ${({
          colorHasPriority,
          backgroundColour,
          theme,
          lightSkin,
        }) =>
          backgroundColour
            ? getColorLuminance(backgroundColour, -0.05)
            : !colorHasPriority
            ? "rgba(255,255,255,0.1)"
            : lightSkin
            ? theme.colors.tusk[200]
            : theme.colors.midnight[200]};
      }
    }
  }
  .content .button {
    margin-top: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .content-container {
      margin-bottom: 30px;
    }
    .benefits-wrapper .benefit.cols-2.odd .inner,
    .benefits-wrapper .benefit.cols-2.even .inner {
      grid-template-columns: 1fr;
      .image {
        grid-row: 1;
        grid-column: 1;
      }
      .content {
        grid-row: 2;
        grid-column: 1;
      }
    }
  }
`
