import React from "react"

import BenefitsLayoutA from "./layouts/LayoutA"
import BenefitsLayoutB from "./layouts/LayoutB"
import BenefitsLayoutC from "./layouts/LayoutC"
import { getVerticalSpacing } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Testimonials = ({
  title,
  layout,
  duration,
  autoPlay,
  textColour,
  description,
  assetColours,
  testimonials,
  contentLayout,
  verticalSpacing,
  backgroundColour,
  backgroundImage,
  boxBackgroundColour,
}) => {
  const colorHasPriority = backgroundImage === null
  const isPreview = backgroundImage?.mediaItemUrl
  // Swap out Layout
  let Layout = BenefitsLayoutA
  switch (layout) {
    case "a":
      Layout = BenefitsLayoutA
      break
    case "b":
      Layout = BenefitsLayoutB
      break
    case "c":
      Layout = BenefitsLayoutC
      break
    default:
      Layout = BenefitsLayoutA
      break
  }

  return (
    <Layout
      title={title}
      isPreview={isPreview}
      duration={duration}
      autoPlay={autoPlay}
      textColour={textColour}
      description={description}
      assetColours={assetColours}
      testimonials={testimonials}
      contentLayout={contentLayout}
      backgroundImage={backgroundImage}
      backgroundColour={backgroundColour}
      colorHasPriority={colorHasPriority}
      boxBackgroundColour={boxBackgroundColour}
      vSpacing={getVerticalSpacing(verticalSpacing)}
    />
  )
}
export default Testimonials
