import replaceNodes from "./replaceNodes"

const IMAGE_SELECTION_SET = {
  kind: "SelectionSet",
  selections: [
    {
      kind: "Field",
      name: { kind: "Name", value: "mediaItemUrl" },
      arguments: [],
      directives: [],
    },
  ],
}

function removeGatsbyImages(ast) {
  return replaceNodes(
    [
      "image",
      "backgroundImage",
      "backgroundImageDesktop",
      "backgroundImageMobile",
      "avatar",
      "logos",
      "images",
      "featuredImage",
    ],
    IMAGE_SELECTION_SET,
    ast
  )
}

function removeGatsbyImagesPost(ast) {
  return replaceNodes(
    [
      "image",
      "backgroundImage",
      "backgroundImageDesktop",
      "backgroundImageMobile",
      "logos",
      "images",
      "featuredImage",
    ],
    IMAGE_SELECTION_SET,
    ast
  )
}

export { removeGatsbyImagesPost }
export default removeGatsbyImages
