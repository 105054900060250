import React, { useState, useEffect } from "react"
import styled from "styled-components"
import posed, { PoseGroup } from "react-pose"
import BackgroundImage from "gatsby-background-image"

import Button from "../../dsm/buttons"
import Inner from "../../dsm/layout/Inner"
import Icon from "../../dsm/icons/icon"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"
import { getInverseColour, getAssetColours } from "../../utils"
import { useDetectSmall } from "../../hooks/useDetectMobile"

//////////////////////////////////////////////////////////////////////////////
// 🛠 Component
//////////////////////////////////////////////////////////////////////////////
const SliderBlock = ({ slides, autoRotate = true }) => {
  const { sliderConfig } = getFlexiOptionsLayouts()
  const { shape } = getFlexiOptionsBranding()
  const isMobileSmall = useDetectSmall()

  // Slider Specific Functions
  const duration = sliderConfig.duration
  const [active, setActive] = useState(0)

  const next = () => setActive((p) => (p === slides.length - 1 ? 0 : p + 1))
  const prev = () => setActive((p) => (p === 0 ? slides.length - 1 : p - 1))

  const [start, setStart] = useState(true)
  const setStartFalse = () => setStart(false)

  useEffect(() => {
    if (autoRotate) setStart(true)
    setTimeout(setStartFalse, 200)
  }, [active, autoRotate])

  return (
    <Section
      assetColours={sliderConfig.assetColours}
      showProgress={sliderConfig.showProgressBar}
    >
      {autoRotate && (
        <ProgressBar
          className="progress-bar"
          pose={start ? "start" : "end"}
          onPoseComplete={next}
          delay={duration}
        />
      )}
      {!isMobileSmall && sliderConfig.showDots && (
        <Dots
          total={slides.length}
          active={active}
          setActive={setActive}
          assetColours={sliderConfig.assetColours}
        />
      )}
      {sliderConfig.showArrows && (
        <Arrows
          next={next}
          prev={prev}
          shape={shape}
          assetColours={sliderConfig.assetColours}
        />
      )}
      <PoseGroup>
        <Pose key={active}>
          <Slide
            slide={slides[active]}
            next={next}
            prev={prev}
            shape={shape}
            showArrows={sliderConfig.showArrows}
          />
        </Pose>
      </PoseGroup>
    </Section>
  )
}
export default SliderBlock

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Section = styled.section`
  /* margin-top: -1px; */
  background: ${({ theme }) => theme.colors.midnight[600]};
  position: relative;
  .progress-bar {
    height: ${({ showProgress }) => (showProgress ? "3px" : "0")};
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ assetColours }) => getAssetColours(assetColours)};
    opacity: 0.5;
    width: 0%;
    z-index: 9;
  }
`

///////////////////////////////////////
// ⚡️ Animation
///////////////////////////////////////
const ProgressBar = posed.div({
  start: { width: "0%" },
  end: {
    width: "100%",
    transition: ({ delay }) => ({ duration: delay - 200, ease: "linear" }),
  },
})

const Pose = posed.div({
  enter: { opacity: 1, delay: 150, x: 0 },
  exit: { opacity: 0, x: 25 },
})

//////////////////////////////////////////////////////////////////////////////
// 🛠 Component
//////////////////////////////////////////////////////////////////////////////
const Slide = ({ slide, showArrows }) => {
  const isPreview = slide?.backgroundImageDesktop?.mediaItemUrl

  const wrapperProps = isPreview
    ? { background: `url(${isPreview})`, as: "div" }
    : {
        fluid: [
          slide?.backgroundImageMobile?.imageFile?.childImageSharp?.fluid,
          {
            ...slide?.backgroundImageDesktop?.imageFile?.childImageSharp?.fluid,
            media: `(min-width: 769px)`,
          },
        ],
      }

  const textColour = slide.textColour !== null ? slide.textColour : "#222222"
  const headingColour =
    slide.headingColour !== null ? slide.headingColour : "#222222"

  return (
    <SlideWrapper
      {...wrapperProps}
      showArrows={showArrows}
      textColour={textColour}
      backgroundColour={slide.backgroundColour}
      backgroundSize={slide.backgroundSize}
      backgroundPosition={slide.backgroundPosition}
      backgroundSizeMobile={slide.backgroundSizeMobile}
      backgroundPositionMobile={slide.backgroundPositionMobile}
    >
      <Inner>
        <div className="content-wrapper">
          <div className="content-inner">
            <Heading
              as={slide.headingSize}
              headingColour={headingColour}
              className="heading"
            >
              {slide.heading}
            </Heading>
            <p
              dangerouslySetInnerHTML={{ __html: slide.description }}
              className="large"
            />
            {slide.hasCta && (
              <Button
                to={slide.cta.link}
                isExternal={slide.cta.isExternal}
                label={slide.cta.label || "Find out More"}
                type="outline"
                icon={slide.cta.icon}
                color={textColour}
              />
            )}
          </div>
        </div>
      </Inner>
    </SlideWrapper>
  )
}

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const SlideWrapper = styled(BackgroundImage)`
  padding: 40px 0;
  min-height: calc(600px - 80px);
  background-color: ${({ backgroundColour }) => backgroundColour};
  background-position: ${({ backgroundPosition }) =>
    backgroundPosition} !important;
  background-repeat: no-repeat;
  background-size: ${({ backgroundSize }) => backgroundSize} !important;
  ${({ background }) => (background ? "background: " + background + ";" : "")}
  .inner {
    display: grid;
    min-height: 520px;
    align-items: center;
    padding-left: ${({ showArrows }) => (showArrows ? "100px" : "20px")};
  }
  .content-wrapper {
    width: 45%;
    h2 {
      font-size: 3.5rem;
    }
    h3 {
      font-size: 3.052rem;
    }
    h4 {
      font-size: 2.441rem;
    }
    h5 {
      font-size: 1.953rem;
    }
    h6 {
      font-size: 1.563rem;
    }
    p {
      color: ${({ textColour }) => textColour};
      margin: 20px 0 30px 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .content-wrapper {
      width: 50%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 0;
    background-position: ${({ backgroundPositionMobile }) =>
      backgroundPositionMobile} !important;
    background-size: ${({ backgroundSizeMobile }) =>
      backgroundSizeMobile} !important;
    &:before,
    &:after {
      background-position: ${({ backgroundPositionMobile }) =>
        backgroundPositionMobile} !important;
      background-size: ${({ backgroundSizeMobile }) =>
        backgroundSizeMobile} !important;
    }
    .inner {
      padding: 0;
      align-items: flex-end;
    }
    .content-wrapper {
      width: 100%;
      padding-top: 75%;
    }
    .content-inner {
      padding: 20px;
      background-color: ${({ textColour }) =>
        `${getInverseColour(textColour)}99`};
      backdrop-filter: blur(10px);
    }
  }
`
const Heading = styled.h3`
  color: ${({ headingColour }) => headingColour};
  margin-top: 0;
  line-height: 1;
`

//////////////////////////////////////////////////////////////////////////////
// 🛠 Component
//////////////////////////////////////////////////////////////////////////////
const Dots = ({ total, active, setActive, assetColours }) => {
  const createDots = (total, active) => {
    let dots = []
    for (let i = 0; i < total; i++) {
      dots.push(
        <div
          key={i}
          role="button"
          className={`dot dot-${i} ${active === i ? "active" : "inactive"}`}
          onClick={() => setActive(i)}
          onKeyDown={(e) => (e.key === "Enter" ? setActive(i) : null)}
          aria-hidden="true"
          tabIndex="0"
        />
      )
    }
    return dots
  }
  return (
    <DotsWrapper assetColours={assetColours}>
      {createDots(total, active)}
    </DotsWrapper>
  )
}

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const DotsWrapper = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  .dot {
    width: 10px;
    height: 10px;
    margin: 5px;
    display: inline-block;
    border-radius: 50px;
    border: 2px solid ${({ assetColours }) => getAssetColours(assetColours)};
    &.active {
      background-color: ${({ assetColours }) => getAssetColours(assetColours)};
    }
    &.inactive:hover {
      background-color: ${({ assetColours }) => getAssetColours(assetColours)};
      cursor: pointer;
      opacity: 0.5;
    }
  }
`

//////////////////////////////////////////////////////////////////////////////
// 🛠 Component
//////////////////////////////////////////////////////////////////////////////
const Arrows = ({ next, prev, shape, assetColours }) => {
  return (
    <>
      <Arrow
        className="prev"
        onClick={prev}
        shape={shape}
        assetColours={assetColours}
      >
        <Icon name="arrow-right" />
      </Arrow>
      <Arrow
        className="next"
        onClick={next}
        shape={shape}
        assetColours={assetColours}
      >
        <Icon name="arrow-right" />
      </Arrow>
    </>
  )
}

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Arrow = styled.button`
  padding: 18px;
  background-color: ${({ assetColours }) =>
    `${getAssetColours(assetColours)}4D`};
  border: none;
  border-radius: ${({ shape }) => shape};
  position: absolute;
  z-index: 2;
  top: calc(300px - 30px);
  svg #icon {
    fill: ${({ assetColours }) =>
      getInverseColour(getAssetColours(assetColours))};
  }
  &.prev {
    left: 20px;
    svg {
      transform: rotate(180deg);
    }
  }
  &.next {
    right: 20px;
  }
  transition: all 200ms ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: ${({ assetColours }) => getAssetColours(assetColours)};
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    top: 20px;
  }
`
